import React, { useEffect, useState, useRef, useContext } from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import styled from 'styled-components/macro';
import { FormContext } from '../../../contexts/FormContext';

import { Button, FormLabel, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import GetUploadUrl from '../../../utils/GetUploadUrl';
import UploadFile from '../../../utils/UploadFile';
import { resizeImage } from '../../../utils/ImageResizer';
import { s3Path } from '../../../config/Config';

const ModifiedFormGroup = styled(FormGroup)(spacing);
const MuiTextFieldStyled = styled(MuiTextField)(spacing);

const Image = styled.img`
  width: 320px;
`;

const Video = styled.video`
  width: 360px;
  height: 240px;
  display: block;
`;

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ImageUpload = props => {
  const [value, setValue] = useState('');
  const context = useContext(FormContext);
  let [progress, setProgress] = useState(0);
  const [lastValue, setLastValue] = useState('');
  const [completed, setCompleted] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [defaultImageCheckboxValue, setDefaultImageCheckboxValue] = useState(false);

  function parseChange(e) {
    // console.log("parse change ",e.detail.value,value)
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);
    let field;
    if (props.data.nested) {
      field = props.data.subField;
    } else {
      field = props.data.field;
    }
    let newValue = context.getValue(field);
    setValue(newValue);
    let checked = checkValue(newValue);
    context.setFieldStatus(field, checked.result, checked.error);
    setIsVisible(context.checkVisible(field));

    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  useEffect(() => {
    checkAndUpdateValue(value);
    //console.log("useeffect value updated",value)
  }, [value]);

  function externalValueUpdated(field, externalvalue) {
    if (props.data.nested) {
      if (field === props.data.subField) {
        setValue(externalvalue);
      }
    } else {
      if (field === props.data.field) {
        setValue(externalvalue);
      }
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if (value.length == 0) {
        result = false;
        error = props.data.name + ' is required';
      } else {
        result = true;
      }
    }

    if (!completed) {
      result = false;
      error = 'File is still uploading, please wait';
    }
    return { result: result, error: error };
  }

  function uploadProgress(uploadProgress) {
    console.log('uploading ', uploadProgress);
    setProgress(uploadProgress);
    if (uploadProgress == 100) {
      console.log('SET COMPLETED TRUE');
      setCompleted(true);
      checkAndUpdateValue(value);
    }
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  async function uploadChanged(e) {
    if (!e.target.files[0]) {
      return;
    }
    var file = e.target.files[0];
    let filename = file.name;
    let url = await GetUploadUrl(context.data, props.data.uploadFolder + '/' + filename, props.data.apiType);
    if (url.url != '') {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      if (props.data.resize) {
        reader.onload = async function () {
          let result = reader.result;
          if (!result) {
            return;
          }
          if (props.data.resize.width != undefined && props.data.resize.height != undefined && props.data.resize.type != undefined) {
            let tempresult = await resizeImage(
              result,
              props.data.resize.width,
              props.data.resize.height,
              props.data.resize.type,
              props.data.resize.compression,
              props.data.resize.bgcolor
            );
            if (tempresult) {
              file = tempresult;
            }

            let newfile = dataURLtoFile(file, filename);

            setCompleted(false);

            await UploadFile(url.url, newfile, url.mime, uploadProgress);

            updateValue('/' + url.file);
          }
        };
      } else {
        setCompleted(false);
        await UploadFile(url.url, file, url.mime, uploadProgress);
        updateValue(url.file);
      }
    }
  }

  function checkAndUpdateValue(val) {
    let checked = checkValue(val);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, val, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
    }
  }

  const updateValue = val => {
    console.log('updateValue', val);
    const splitVal = val.split('/');
    console.log('splitVal', splitVal, splitVal[splitVal.length - 1], props.data.uploadFolder);
    const modifiedImageLocation = `/${props.data.uploadFolder}/${splitVal[splitVal.length - 1]}`;
    console.log(modifiedImageLocation);
    setValue(modifiedImageLocation);
    let checked = checkValue(modifiedImageLocation);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, val, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
    }
  };

  function GetPath(val) {
    console.log('getPath', val);
    if (val.indexOf('https:') !== -1) {
      return val;
    } else {
      let url = s3Path;
      if (val.substring(0, 1) != '/') {
        url += '/';
      }
      url = url + val;
      return url;
    }
  }

  function updateDefaultImageCheckboxValue(event) {
    setDefaultImageCheckboxValue(event.target.checked);
    if (event.target.checked) {
      setLastValue(value);
      checkAndUpdateValue(props.data.defaultImage);
    } else {
      checkAndUpdateValue(lastValue);
    }
  }

  return (
    <>
      {isVisible && (
        <>
          <Container>
            <ModifiedFormGroup>
              <FormLabel component="legend">{props.data.name}</FormLabel>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id={'raised-button-file' + props.data.field}
                multiple
                type="file"
                onChange={uploadChanged}
              />
              <label htmlFor={'raised-button-file' + props.data.field}>
                <Button variant="contained" component="span" color="primary">
                  Upload afbeelding
                </Button>
              </label>
              Voortgang {progress}
              {completed && value != '' && <Image src={GetPath(value)} />}
              {props.data.defaultImage && (
                <FormControlLabel
                  control={<Checkbox checked={defaultImageCheckboxValue} onChange={updateDefaultImageCheckboxValue}></Checkbox>}
                  label="Gebruik standaard afbeelding"
                ></FormControlLabel>
              )}
            </ModifiedFormGroup>
          </Container>
        </>
      )}
    </>
  );
};

export default ImageUpload;
