import './css/tags.css';
import styled from 'styled-components/macro';
import { FormGroup, FormLabel } from '@material-ui/core';
import { WithContext as ReactTags } from 'react-tag-input';
import { FormContext } from '../../../contexts/FormContext';
import React, { useEffect, useState, useContext } from 'react';

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Tags = props => {
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const context = useContext(FormContext);

  const KeyCodes = {
    comma: 188,
    enter: 13
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);
    let newvalue = context.getValue(props.data.field);
    setValue(newvalue);
    let checked = checkValue(newvalue);
    context.setFieldStatus(props.data.field, checked.result, checked.error);
    setIsVisible(context.checkVisible(props.data.field));
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  useEffect(() => {
    checkAndUpdateValue(value);
    makeTags();
  }, [value]);

  useEffect(() => {
    makeValue();
  }, [tags]);

  function makeTags() {
    let newtags = [];
    value.forEach(item => {
      newtags.push({ id: item, text: item });
    });
    setTags(newtags);
  }

  function externalValueUpdated(field, externalvalue) {
    if (field == props.data.field) {
      setValue(externalvalue);
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if (value.length == 0) {
        result = false;
        error = props.data.name + ' is required';
      } else {
        result = true;
      }
    }
    return { result: result, error: error };
  }

  function checkAndUpdateValue(val) {
    let checked = checkValue(val);
    context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
  }

  const valueUpdated = event => {
    let checked = checkValue(event.target.value);
    context.setFieldValue(props.data.field, event.target.value, checked.result, checked.error, props.data.dataTypes);
  };

  const makeValue = function () {
    let newvalue = [];
    tags.forEach(item => {
      newvalue.push(item.text);
    });
    if (newvalue.length != value.length) {
      checkAndUpdateValue(newvalue);
    }
  };

  const handleDelete = function (i) {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = function (tag) {
    setTags([...tags, tag]);
  };

  const handleDrag = function (tag, currPos, newPos) {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags({ newTags });
  };

  return (
    <>
      {isVisible && (
        <Container>
          <FormGroup>
            <FormLabel component="legend">{props.data.name}</FormLabel>
            <ReactTags
              tags={tags}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              delimiters={delimiters}
            />
          </FormGroup>
        </Container>
      )}
    </>
  );
};

export default Tags;
