import ImageFitter from "./ImageFitter"

export async function resizeImage(imgdata,width,height,scaletype,quality,bgcolor) {

    if (!quality) {
        quality = 0.75;
    }

    const canvas = document.createElement('canvas');
    const img = document.createElement('img');
    return new Promise((resolve, reject) => {  
        img.src = imgdata;
        img.onload = async ()=>{
            let data = ImageFitter(scaletype,width,height,img.width,img.height);

            if (!data){
                return false;
            }
            
            canvas.width = data.containerWidth;
            canvas.height = data.containerHeight;
            
            if (bgcolor) {
                canvas.getContext('2d').fillStyle = bgcolor;
                canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height);
            }
            canvas.getContext('2d').drawImage(img, data.x, data.y, data.width, data.height); 
           
            if (!img.width) {
                reject();
            }
            else {
                resolve(canvas.toDataURL('image/jpeg', quality)); 
            }
        }
    })

    
};