const { API, graphqlOperation } = require('aws-amplify');

module.exports = async data =>
  API.graphql(graphqlOperation(data, {})).catch(error => {
    handleGQLError(error);
  });

const handleGQLError = error => {
  let message = error.errors[0].message;
  switch (error.errors[0].message) {
    case 'existing_user_name':
      message = 'Deze gerbruikersnaam bestaat al.';
      break;
  }
  alert(`Er ging iets fout: ${message}`);
};
