import React, { useEffect, useState, useRef, useContext } from 'react';
import { InputLabel, MenuItem, FormControl as MuiFormControl, Select } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import styled from 'styled-components/macro';
import { FormContext } from '../../../contexts/FormContext';
import GetOptions from '../../../utils/cms/GetOptions';

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
`;

const DropDown = props => {
  const context = useContext(FormContext);
  const [value, setValue] = useState(props.value ?? '');
  const [isVisible, setIsVisible] = useState(false);
  const [options, setOptions] = useState([]);

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  useEffect(() => {
    let field, newValue;
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);

    if (props.data.nested) field = props.data.subField;
    else field = props.data.field;

    newValue = context.getValue(field);

    setValue(newValue);
    let checked = checkValue(newValue);
    context.setFieldStatus(field, checked.result, checked.error);
    if (props.force) {
      setIsVisible(true);
    } else {
      setIsVisible(context.checkVisible(field));
    }

    makeOptions();
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  async function makeOptions() {
    let HTMLOptions = [];

    if (props.data.options) {
      let parsedOptions = await GetOptions(props.data.options);

      parsedOptions.forEach(option => {
        let displayValue;

        if (typeof option.value === 'function') displayValue = option.value(context);
        else displayValue = option.value;

        HTMLOptions.push(
          <MenuItem value={option.key} key={option.key}>
            {displayValue}
          </MenuItem>
        );
      });
    }
    setOptions(HTMLOptions);
  }

  useEffect(() => {
    checkAndUpdateValue(value);
    makeOptions();
  }, [value]);

  function externalValueUpdated(field, externalvalue) {
    if (props.data.nested) {
      if (field === props.data.subField) {
        setValue(externalvalue);
      }
    } else {
      if (field === props.data.field) {
        setValue(externalvalue);
      }
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if ((value !== 0 && !value) || value === '') {
        if (props.data.default) {
          setValue(props.data.default);
          result = true;
        } else {
          result = false;
          error = props.data.name + ' is required';
        }
      } else {
        result = true;
      }
    }

    return { result: result, error: error };
  }

  function checkAndUpdateValue(val) {
    let checked = checkValue(val);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, val, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
    }
  }

  const valueUpdated = event => {
    let checked = checkValue(event.target.value);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, event.target.value, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, event.target.value, checked.result, checked.error, props.data.dataTypes);
    }
  };

  return (
    <>
      {isVisible && (
        <FormControl m={2}>
          <InputLabel id={'label-' + props.data.field}>{props.data.name}</InputLabel>
          <Select
            disabled={props.data.disabled && props.editID}
            labelId={'label-' + props.data.field}
            value={value}
            fullWidth
            label={props.data.name}
            onChange={valueUpdated}
          >
            {options}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default DropDown;
