import { API, graphqlOperation } from 'aws-amplify';

async function convertMedia(file) {
  let mutation = `mutation MyMutation {
  convertMedia(file: "${file}", type: "CMS") {
    url
    jobId
    convertedLocation
  }
}`;

  const res = await API.graphql(graphqlOperation(mutation, {})).catch(error => {
    console.log('GRAPHQL ERROR', error);
    alert('oeps! ', error);
  });

  return res;
}

export default convertMedia;
