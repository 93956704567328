import Tags from './Fields/Tags';
import Link from './Fields/Link';
import DateTime from './Fields/DateTime';
import CheckBox from './Fields/CheckBox';
import DropDown from './Fields/DropDown';
import TextField from './Fields/TextField';
import M3U8Player from './Fields/M3U8Player';
import CheckBoxes from './Fields/CheckBoxes';
import VideoUpload from './Fields/VideoUpload';
import ImageUpload from './Fields/ImageUpload';
import SelectTransfer from './Fields/SelectTransfer';
import MediaField from './Fields/MediaField';

export function Fields() {
  let fields = {
    Tags: Tags,
    Link: Link,
    DateTime: DateTime,
    DropDown: DropDown,
    CheckBox: CheckBox,
    TextField: TextField,
    CheckBoxes: CheckBoxes,
    MediaField: MediaField,
    M3U8Player: M3U8Player,
    ImageUpload: ImageUpload,
    VideoUpload: VideoUpload,
    SelectTransfer: SelectTransfer
  };
  return fields;
}

export function Parsers() {
  let fields = {};
  return fields;
}
