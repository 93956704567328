const User = require('./User');
const SharedData = require('./SharedData');
module.exports = class Notes extends SharedData {
  constructor() {
    super({
      id: 'id',
      nameField: 'user_notes',
      nameSingle: 'opmerking',
      nameMultiple: 'opmerkingen',
      apiCallReturnSubtype: 'user_notes',
      options: ['edit', 'delete', 'insert'],
      search: {
        searchValues: {
          body: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          employee_id
          user_id
          body
          in_admin
          created_at
        }`
      },
      update: {
        apiCall: 'userNotesCreateUpdate',
        apiCallReturnSubtype: null,
        apiInput: `id: idValue, input: { inputStructure }`,
        apiReturnStructure: `{
          extra
          id
          result
          token
        }`
      },
      delete: {
        apiCall: 'deleteNotes'
      }
    });
    this.in_admin = null;

    this.defaultOrder = 'created_at desc'

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Body',
        field: 'body',
        type: 'TextField',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Aangemaakt op',
        field: 'created_at',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Zichtbaar Clowns',
        field: 'in_admin',
        type: 'DropDown',
        required: true,
        list: false,
        edit: true,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      }
    ];

    this.filters = [
      {
        name: 'En redactie',
        type: 'DropDown',
        field: 'in_admin',
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ],
        default: 1
      }
    ];

    return this;
  }
};
