const SharedData = require('./SharedData');
module.exports = class Cussword extends SharedData {
  constructor() {
    super({
      id: 'id',
      nameField: 'word',
      nameSingle: 'scheldwoord',
      nameMultiple: 'scheldwoorden',
      apiCallReturnSubtype: 'cusswords',
      options: ['edit', 'delete', 'insert'],
      search: {
        searchValues: {
          id: 'number',
          word: 'string',
          replacement: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          word
          replacement
          created_at
          updated_at
          deleted_at
        }`
      },
      update: {
        apiCall: 'updateCussword',
        apiCallReturnSubtype: null,
        apiInput: `id: idValue, input: { inputStructure }`,
        apiReturnStructure: `{
          result
          id
          extra
          token
        }`
      },
      delete: {
        apiCall: 'deleteCussword'
      }
    });

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Woord',
        field: 'word',
        type: 'TextField',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Vervanging',
        field: 'replacement',
        type: 'TextField',
        required: true,
        list: true,
        edit: true
      }
    ];

    return this;
  }
};
