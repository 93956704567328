import { v4 } from 'uuid';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { spacing } from '@material-ui/system';
import { Search } from '../../../utils/Search';
import capitalize from '../../../utils/capitalize';
import React, { useEffect, useContext } from 'react';
import { FormContext } from '../../../contexts/FormContext';
import FormGenerator from '../../FormGenerator/FormGenerator';
import InsertUpdateRecord from '../../../utils/InsertUpdateRecord';
import { startMediaConversion } from '../../../utils/HelperFunctions';
import checkMediaConversionStatus from '../../../utils/checkMediaConverstionStatus';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Card as MuiCard, Paper as MuiPaper } from '@material-ui/core';

const queryString = require('query-string');

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Image = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const Saving = styled.div`
  padding-right: 20px;
`;

const EditListForm = props => {
  const context = useContext(FormContext);
  const [open, setOpen] = React.useState(false);
  const mediaConversionResult = React.useRef(null);
  const [loaded, setLoaded] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const user = useSelector(state => state.authReducer.user);
  const [downloading, setDownloading] = React.useState(false);
  const groups = user?.signInUserSession.accessToken.payload['cognito:groups'] ?? [];

  const dataObject = new props.data.data();

  const handleClose = () => {
    props.OpenRecord(null);
    context.setRecordType(null);
  };

  function parseChange(e) {
    if (e.detail.field == 'type') {
      context.setRecordType(e.detail.value);
    }
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    return () => {
      context.events.removeEventListener('change', parseChange);
    };
  }, []);

  useEffect(async () => {
    setLoaded(false);
    setSaving(false);
    if (props.editID != null) {
      emptyForm();
      setOpen(true);
      if (props.editID != 0) {
        await loadItem(props.editID);
      } else {
        setLoaded(true);
      }
    } else {
      setOpen(false);
    }
  }, [props.editID]);

  function emptyForm() {
    context.clear();
  }

  async function loadItem(id) {
    let data;

    if (dataObject.searchForOne) data = await dataObject.searchForOne({ search: id });
    else data = await Search(dataObject, { search: id, searchForOne: true, queryLimits: { page: 0, perpage: 1 } });

    if (!data.rows || data.rows.length === 0) return;

    let row = data.rows[0];
    await context.setData(row);
    if (dataObject.multiType && row[dataObject.multiTypeId]) context.setRecordType(row.type);
    setLoaded(true);
  }

  const handleSave = async () => {
    setLoaded(false);
    if (dataObject.options.indexOf('edit') !== -1 && context.checkForm()) {
      setSaving(true);
      let id = null;
      if (props.editID != 0) {
        id = props.editID;
      } else if (window.location.href.indexOf('subId') !== -1 && dataObject.nameField === 'user_notes') {
        const queryParams = queryString.parse(window.location.href.split('?')[1]);

        context.setFieldValue('user_id', [queryParams.subId], true);
      }
      const update = await InsertUpdateRecord(dataObject, context.getFields(), id, context.recordType).catch(err => {
        setSaving(false);
      });

      if (update) {
        props.Refresh();
        setSaving(false);
        handleClose();
      }
    }
    setLoaded(true);
  };

  const handleDownload = async (e, skipConversion = false) => {
    setDownloading(true);
    const files = context.row[dataObject.download.downloadLinkField];
    if (!skipConversion) mediaConversionResult.current = await startMediaConversion(files);

    try {
      const res = await checkMediaConversionStatus(Object.keys(mediaConversionResult.current));

      if (!res || res.length < files.length) throw new Error('retry-download');

      for (let i = 0; i < res.length; i++) {
        const file = res[i];
        const a = document.createElement('a');

        a.href = file.signedUrl;
        a.type = dataObject.download.fileType;
        a.click();

        await new Promise(resolve =>
          setTimeout(() => {
            resolve();
          }, 500)
        );
      }
    } catch (error) {
      console.log('RETRYING DOWNLOAD', error);
      await new Promise(resolve => {
        setTimeout(async () => {
          await handleDownload(e, true);
          resolve();
        }, 5000);
      });
    }

    setDownloading(false);
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Paper mt={4}>
            <React.Fragment>
              <Dialog fullWidth={true} maxWidth={'xl'} open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">
                  {capitalize(dataObject.nameSingle)}
                  <Image
                    onClick={handleClose}
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNDQzLjYsMzg3LjFMMzEyLjQsMjU1LjRsMTMxLjUtMTMwYzUuNC01LjQsNS40LTE0LjIsMC0xOS42bC0zNy40LTM3LjZjLTIuNi0yLjYtNi4xLTQtOS44LTRjLTMuNywwLTcuMiwxLjUtOS44LDQgIEwyNTYsMTk3LjhMMTI0LjksNjguM2MtMi42LTIuNi02LjEtNC05LjgtNGMtMy43LDAtNy4yLDEuNS05LjgsNEw2OCwxMDUuOWMtNS40LDUuNC01LjQsMTQuMiwwLDE5LjZsMTMxLjUsMTMwTDY4LjQsMzg3LjEgIGMtMi42LDIuNi00LjEsNi4xLTQuMSw5LjhjMCwzLjcsMS40LDcuMiw0LjEsOS44bDM3LjQsMzcuNmMyLjcsMi43LDYuMiw0LjEsOS44LDQuMWMzLjUsMCw3LjEtMS4zLDkuOC00LjFMMjU2LDMxMy4xbDEzMC43LDEzMS4xICBjMi43LDIuNyw2LjIsNC4xLDkuOCw0LjFjMy41LDAsNy4xLTEuMyw5LjgtNC4xbDM3LjQtMzcuNmMyLjYtMi42LDQuMS02LjEsNC4xLTkuOEM0NDcuNywzOTMuMiw0NDYuMiwzODkuNyw0NDMuNiwzODcuMXoiLz48L3N2Zz4="
                  ></Image>
                </DialogTitle>
                <DialogContent>
                  <FormGenerator {...props}></FormGenerator>
                </DialogContent>
                <DialogActions>
                  {loaded && dataObject.options.indexOf('download') === -1 ? (
                    <Button onClick={handleSave} color="primary">
                      Opslaan
                    </Button>
                  ) : (
                    loaded &&
                    !downloading &&
                    groups.includes('admins') && (
                      <Button disabled={downloading} onClick={handleDownload} color="primary">
                        Download
                      </Button>
                    )
                  )}
                  {downloading && <Saving>Wacht even op je download...</Saving>}
                  {saving && <Saving>Opslaan...</Saving>}
                </DialogActions>
              </Dialog>
            </React.Fragment>
          </Paper>
        </CardContent>
      </Card>
    </>
  );
};

export default EditListForm;
