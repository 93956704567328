const { Search } = require('@material-ui/icons');
const User = require('../Data/User');
const SharedData = require('./SharedData');
module.exports = class Chatroom extends SharedData {
  constructor() {
    super({
      id: 'id',
      nameField: 'chatrooms',
      nameSingle: 'chat',
      nameMultiple: 'chats',
      apiCallReturnSubtype: 'chatrooms',
      options: ['delete', 'deep'],
      search: {
        searchValues: {
          id: 'number',
          name: 'string',
        }
      },
      get: {
        apiReturnStructure: `{
          id
          name
          users
          user_ids
          updated_at
          last_message
        }`
      },
      update: {
        apiCall: 'updateChatroom',
        apiInput: `id: idValue, input: { inputStructure }`,
        apiReturnStructure: `{
          result
          id
          extra
        }`
      },
      delete: {
        apiCall: 'CMSDeleteChatrooms'
      }
    });

    this.inputConstants = {
      is_show_chatroom: 0
    };

    this.new = true;
    this.deleteApi = '';

    this.operand = 1;

    this.defaultOrder = 'updated_at desc';

    this.subIdList = [
      {
        id: 'id',
        name: Search,
        header: 'Berichten',
        field: 'chat_messages',
        deepSearchId: 'chatroom_id',
        link: '/app/berichten?subId=id&subIdField=chat_messages'
      }
    ];

    this.modifyOnInsertUpdate = {
      user_ids: this.modifyUserIds
    };

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Gebruikers',
        field: 'users',
        list: true,
        edit: false,
        required: true,
        customDisplay: this.parseUserName
      },
      {
        name: 'Naam',
        field: 'name',
        type: 'TextField',
        required: false,
        list: true,
        edit: true
      },
      {
        name: 'Gebruikers',
        field: 'user_ids',
        type: 'SelectTransfer',
        options: new User({ constants: { chat_allowed: 1 } }),
        required: false,
        list: false,
        edit: true,
        keyType: 'int'
      },
      {
        name: '	Geupdate op',
        field: 'updated_at',
        type: 'Display',
        required: false,
        list: true,
        edit: false
      }
    ];

    return this;
  }

  modifyUserIds = userIds => {
    console.log('modifying userids', userIds, typeof userIds);
    return userIds;
  };

  parseUserName = data => data?.users?.toString();
};
