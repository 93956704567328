import { API, graphqlOperation } from 'aws-amplify';

async function GetUploadUrl(data, file, type) {
  let ret = {};
  let safeFile = JSON.stringify(file);
  let safeType = JSON.stringify(type);

  let query = `
    query MyQuery {
        ${data.requestUploadUrl}(file: ${safeFile} , type: ${safeType}) {
          file
          mime
          url
        }
      }
    `;

  console.log('query', query);

  const res = await API.graphql(graphqlOperation(query, {})).catch(error => {
    console.log('GRAPHQL ERROR', error);
    alert(JSON.stringify(error));
  });

  console.log('uploadUrl res', res);

  if (res.data && res.data[data.requestUploadUrl]) {
    ret = res.data[data.requestUploadUrl];
  }

  return ret;
}

export default GetUploadUrl;
