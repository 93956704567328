const SharedData = require('./SharedData');
module.exports = class Webcamroom extends SharedData {
  constructor(options = {}) {
    super({
      id: 'id',
      nameField: 'id',
      nameSingle: 'videochat',
      nameMultiple: 'videochats',
      apiCallReturnSubtype: 'webcamrooms',
      options: ['view', 'deep', 'download', 'delete'],
      downloadLinkField: 'recording_path',
      search: {
        searchValues: {
          user: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          active
          user_ids
          agora_sid
          created_at
          updated_at
          recording_path
          recording_status
          agora_resource_id
          users {
            user_id
            user_name
          }
        }`
      },
      delete: {
        apiCall: 'CMSDeleteWebcamrooms'
      }
    });

    this.inputConstants = {
      active: 1
    };

    this.operand = 1;

    if (options && options.constants) {
      this.inputConstants = {
        ...this.inputConstants,
        ...options.constants
      };
    }

    this.defaultOrder = 'created_at desc';

    this.subIdList = [
      {
        id: 'user_ids',
        header: 'Gebruikers',
        name: this.parseUserName,
        field: 'users',
        deepSearchId: 'id',
        link: '/app/users?subId=[user_ids]&subIdField=user_ids'
      }
    ];

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Aangemaakt op',
        field: 'created_at',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        field: 'recording_path',
        type: 'M3U8Player',
        view: true,
        required: true
      },
      {
        field: 'user_ids',
        required: true
      }
    ];

    this.filters = [
      {
        name: 'Begint na',
        field: 'start',
        type: 'DateTime'
      },
      {
        name: 'Eindigt voor',
        field: 'end',
        type: 'DateTime'
      }
    ];

    return this;
  }

  parseUserName = data => {
    if (data.users) {
      return data.users.map(user => user.user_name).toString();
    }
  };
};
