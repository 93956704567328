import { rgba, darken } from 'polished';
import { Link as NavLink } from 'gatsby';
import styled from 'styled-components/macro';
import { spacing } from '@material-ui/system';
import { FormContext } from '../../../contexts/FormContext';
import { ListItem, TextField as MuiTextField } from '@material-ui/core';
import React, { useEffect, useState, useRef, useContext } from 'react';

// const MuiTextFieldStyled = styled(MuiTextField)(spacing);

const LinkElement = styled(ListItem)`
  span {
    color: ${props => rgba(props.theme.table.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.table.color, 0.9)};
  }

  &:hover {
    background-color: ${props => darken(0.015, props.theme.table.background)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.03, props.theme.table.background)};

    span {
      color: ${props => props.theme.table.color};
    }
  }
`;

const Link = props => {
  console.log('link.js');
  const context = useContext(FormContext);
  const [value, setValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  function parseChange(e) {
    // console.log('parse change ', e.detail.field, e.detail.value, value, e.detail);
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);
    let field;
    if (props.data.nested) {
      field = props.data.subField;
    } else {
      field = props.data.field;
    }
    let newValue = context.getValue(field);
    setValue(newValue);
    let checked = checkValue(newValue);
    context.setFieldStatus(field, checked.result, checked.error);
    setIsVisible(context.checkVisible(field));
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  useEffect(() => {
    checkAndUpdateValue(value);
    // console.log("useeffect value updated",value)
  }, [value]);

  function externalValueUpdated(field, externalvalue) {
    // console.log('external value to be updated', field, externalvalue, props.data.field, props.data);
    if (props.data.nested) {
      if (field === props.data.subField) {
        // console.log('external value updated set nested value', field, value, externalvalue);
        setValue(externalvalue);
      }
    } else {
      if (field === props.data.field) {
        // console.log('external value updated set value', field, value, externalvalue);
        setValue(externalvalue);
      }
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if (value.length == 0) {
        result = false;
        error = props.data.name + ' is required';
      } else {
        result = true;
      }
    }
    return { result: result, error: error };
  }

  function checkAndUpdateValue(val) {
    // console.log('Update value ', props.data.field, val);
    let checked = checkValue(val);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, val, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
    }
  }

  const valueUpdated = event => {
    let checked = checkValue(event.target.value);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, event.target.value, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, event.target.value, checked.result, checked.error, props.data.dataTypes);
    }
  };

  return (
    <>
      {isVisible && (
        <LinkElement button dense component={NavLink} to={value} activeClassName="active">
          Link
        </LinkElement>
      )}
    </>
  );
};

export default Link;
