module.exports = class SharedData {
  constructor(structure) {
    // constants shared across models
    this.new = true;
    this.requestUploadUrl = 'getUploadUrl';

    // model specific structure
    if (structure) {
      this.id = structure.id;
      this.options = structure.options;
      /* used to provide a value for to display for each record from the query results */
      this.nameField = structure.nameField;
      this.nameSingle = structure.nameSingle;
      this.nameMultiple = structure.nameMultiple;
      this.searchValues = structure.search.searchValues;

      this.get = {
        apiCall: 'search',
        apiCallReturnSubtype: structure.apiCallReturnSubtype,
        apiInput: `queryLimits: { queryLimitValues }, input: { ${structure.apiCallReturnSubtype}: { searchValues filterValues } }, unlimited: unlimitedValue, cms: true`,
        apiReturnStructure: `${structure.apiCallReturnSubtype} ${structure.get.apiReturnStructure}
        queryResultInfo {
          page
          limit
          count
          perpage
        }`
      };

      if (structure.options.indexOf('edit') !== -1 || structure.options.indexOf('insert') !== -1) {
        this.update = {
          apiCall: structure.update.apiCall,
          apiInput: structure.update.apiInput,
          apiReturnStructure: structure.update.apiReturnStructure,
          apiCallReturnSubtype: structure.update.apiCallReturnSubtype
        };
      }

      if (structure.options.indexOf('archive') !== -1) {
        this.archive = {
          label: structure.archive.label,
          field: structure.archive.field,
          apiCall: structure.archive.apiCall,
          dataTypes: structure.archive.dataTypes,
          apiInput: structure.archive.apiInput,
          apiReturnStructure: structure.archive.apiReturnStructure,
          apiCallReturnSubtype: structure.archive.apiCallReturnSubtype
        };
      }

      if (structure.options.indexOf('delete') !== -1) {
        this.delete = {
          apiCall: structure.delete.apiCall,
          apiInput: 'id: idValue',
          apiReturnStructure: `{
            result
          }`
        };
      }

      if (structure.options.indexOf('export') !== -1) {
        this.export = {
          apiCall: structure.export.apiCall,
          apiReturnStructure: `{
            result
            exportFileName
            exportFileLink
          }`
        };
      }

      if (structure.options.indexOf('download') !== -1) {
        this.download = {
          fileType: 'mp4',
          downloadLinkField: structure.downloadLinkField
        };
      }

      if (structure.flags) {
        this.flags = structure.flags;
      }
    }
  }
};
