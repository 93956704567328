import React, { useEffect, useState, useRef, useContext } from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import styled from 'styled-components/macro';
import { FormContext } from '../../../contexts/FormContext';

const MuiTextFieldStyled = styled(MuiTextField)(spacing);

const TextField = props => {
  const context = useContext(FormContext);
  const [value, setValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);
    let field;
    if (props.data.nested) {
      field = props.data.subField;
    } else {
      field = props.data.field;
    }
    let newValue = context.getValue(field);
    setValue(newValue);
    let checked = checkValue(newValue);
    context.setFieldStatus(field, checked.result, checked.error);
    setIsVisible(context.checkVisible(field));
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  useEffect(() => {
    checkAndUpdateValue(value);
  }, [value]);

  function externalValueUpdated(field, externalvalue) {
    if (props.data.nested) {
      if (field === props.data.subField) {
        setValue(externalvalue);
      }
    } else {
      if (field === props.data.field) {
        setValue(externalvalue);
      }
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if (!value || value == '') {
        if (props.data.default) {
          setValue(props.data.default);
          result = true;
        } else {
          result = false;
          error = props.data.name + ' is required';
        }
      } else {
        result = true;
      }
    }
    return { result: result, error: error };
  }

  // function checkValue(value) {
  //   let result = true;
  //   let error = '';
  //   if (props.data.required || (props.editID == 0 && props.createRequired)) {
  //     if (value.length == 0) {
  //       result = false;
  //       error = props.data.name + ' is required';
  //     } else {
  //       result = true;
  //     }
  //   }
  //   return { result: result, error: error };
  // }

  function checkAndUpdateValue(val) {
    let checked = checkValue(val);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, val, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
    }
  }

  const valueUpdated = event => {
    let checked = checkValue(event.target.value);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, event.target.value, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, event.target.value, checked.result, checked.error, props.data.dataTypes);
    }
  };

  return (
    <>
      {isVisible && (
        <MuiTextFieldStyled
          id={props.data.field}
          label={props.data.name}
          InputLabelProps={{ shrink: props.data.input === 'date' || value !== '' ? true : false }}
          variant="outlined"
          value={value}
          fullWidth
          my={1}
          type={props.data.input ? props.data.input : 'text'}
          inputMode={props.data.input ? props.data.input : 'text'}
          onChange={valueUpdated}
        />
      )}
    </>
  );
};

export default TextField;
