import sendGraphQL from './sendGraphQL';

async function InsertUpdateRecord(data, fields, id, recordType) {
  if (data.insertOverride && !id) return data.insert(fields);
  else if (data.updateOverride) return data.update(fields, id);
  let inputStructure = ``;
  let nestedAttributes = {};
  let insertUpdateResults = {};
  let mismatchedFieldKeys = [];

  if (data.modifyOnInsertUpdate) {
    for (const modKey in data.modifyOnInsertUpdate) {
      fields[modKey] = data.modifyOnInsertUpdate[modKey](fields[modKey], fields);
    }
  }

  for (const fieldKey in fields) {
    let nestedFieldKey = false;
    let replaceWithDefault = false;

    if (fields[fieldKey] === undefined || fields[fieldKey] === null) {
      replaceWithDefault = true;
    }

    for (let i = 0; i < data.fields.length; i++) {
      const field = data.fields[i];

      if (replaceWithDefault && (field.field === fieldKey || field.subField === fieldKey)) {
        if (field.default !== undefined) {
          fields[fieldKey] = field.default;
        }
      }

      if (field.nested && field.subField === fieldKey) {
        if (field.dataTypes && recordType && !field.dataTypes.includes(recordType)) {
          if (mismatchedFieldKeys.indexOf(fieldKey) === -1) {
            mismatchedFieldKeys.push(fieldKey);
          }
          continue;
        }

        if (Object.keys(nestedAttributes).indexOf(field.field) === -1) {
          nestedAttributes[field.field] = `${field.field}: { `;
          nestedAttributes[field.field] += `${field.subField}: ${formatFieldValue(fields, fieldKey)} `;
        } else {
          nestedAttributes[field.field] += `${field.subField}: ${formatFieldValue(fields, fieldKey)} `;
        }
        nestedFieldKey = true;
        continue;
      } else {
        if (field.dataTypes && field.field === fieldKey) {
          if (recordType && !field.dataTypes.includes(recordType)) {
            if (mismatchedFieldKeys.indexOf(fieldKey) === -1) {
              mismatchedFieldKeys.push(fieldKey);
            }
            continue;
          }
        }
      }
    }

    if (!nestedFieldKey && mismatchedFieldKeys.indexOf(fieldKey) === -1) {
      inputStructure += `${fieldKey}: ${formatFieldValue(fields, fieldKey)} `;
    }
  }

  for (const key in nestedAttributes) {
    inputStructure += ` ${nestedAttributes[key]}} `;
  }

  for (const constantKey in data.inputConstants) {
    inputStructure += `${constantKey}: ${
      typeof data.inputConstants[constantKey] === 'number' ? `${data.inputConstants[constantKey]}` : `"${data.inputConstants[constantKey]}"`
    } `;
  }

  let apiConfig;

  if (data.options.indexOf('edit') !== -1) {
    apiConfig = 'update';
  } else if (data.options.indexOf('archive') !== -1) {
    apiConfig = 'archive';
  }

  data[apiConfig].apiInput = data[apiConfig].apiInput.replace(/idValue/g, id);
  data[apiConfig].apiInput = data[apiConfig].apiInput.replace(/inputStructure/g, inputStructure);
  let mutation = `
        mutation ${data[apiConfig].apiCall}Mutation {
          ${data[apiConfig].apiCall}(${data[apiConfig].apiInput}) ${data[apiConfig].apiReturnStructure}
        }`;

  const res = await sendGraphQL(mutation);

  if (res.data && res.data[data[apiConfig].apiCall]) {
    insertUpdateResults = res.data[data[apiConfig].apiCall];
  }

  return insertUpdateResults;
}

const formatFieldValue = (fields, fieldKey) =>
  fields[fieldKey] || fields[fieldKey] === 0
    ? typeof fields[fieldKey] === 'number'
      ? `${fields[fieldKey]}`
      : typeof fields[fieldKey] === 'boolean'
      ? Number(fields[fieldKey])
      : Array.isArray(fields[fieldKey])
      ? `[${fields[fieldKey].toString()}]`
      : `"""${fields[fieldKey].replace(/"""/g, '')}"""`
    : null;

export default InsertUpdateRecord;
