import { FormContext } from '../../../contexts/FormContext';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import React, { useEffect, useState, useContext } from 'react';
import { MYSQLDateTime, DateFromMysql } from '../../../utils/HelperFunctions';

const DateTime = props => {
  const context = useContext(FormContext);
  const [value, setValue] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);
    let newvalue = context.getValue(props.data.field);

    if (typeof newvalue == 'string') {
      newvalue = DateFromMysql(newvalue);
    }

    setIsVisible(context.checkVisible(props.data.field));
    let checked = checkValue(newvalue);
    context.setFieldStatus(props.data.field, checked.result, checked.error);

    updateDate(newvalue);

    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  useEffect(() => {
    setSelectedDate(DateFromMysql(value));
  }, [value]);

  function externalValueUpdated(field, externalvalue) {
    if (field == props.data.field && value != externalvalue) {
      setValue(externalvalue);
      let checked = checkValue(externalvalue);
      context.setFieldStatus(props.data.field, checked.result, checked.error);
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (!value) {
      if (props.data.required) {
        return { result: false, error: props.data.field + ' is required' };
      }
    }
    if (props.data.required) {
      if (value.length == 0) {
        result = false;
        error = props.data.field + ' is required';
      } else {
        result = true;
      }
    }
    if (value == 'Invalid Date') {
      result = false;
      error = props.data.name + " is not valid (keep in mind that the field needs leading zero's. (so 01 instead of 1)";
    }
    return { result: result, error: error };
  }

  function updateDate(date) {
    let result = checkValue(date);
    context.setFieldStatus(props.data.field, result.result, result.error);
    if (result.result) {
      valueUpdated(MYSQLDateTime(date));
    }
  }

  const valueUpdated = newvalue => {
    setValue(newvalue);
    let checked = checkValue(newvalue);
    context.setFieldValue(props.data.field, newvalue, checked.result, checked.error, props.data.dataTypes);
  };

  return (
    <>
      {isVisible && (
        <KeyboardDateTimePicker
          error={false}
          variant="outlined"
          fullWidth
          helperText=""
          defaultValue={null}
          initialFocusedDate={null}
          margin="normal"
          label={props.data.name}
          value={selectedDate}
          ampm={false}
          onChange={updateDate}
          format="dd-MM-yyyy HH:mm"
          InputLabelProps={{ shrink: props.data.input === 'date' || value !== '' ? true : false }}
        />
      )}
    </>
  );
};

export default DateTime;
