import { Fields } from './FormFields';
import { FormContext } from '../../contexts/FormContext';
import React, { useEffect, useState, useContext } from 'react';

const FormGenerator = props => {
  const [formHTML, setFormHTML] = useState([]);

  const dataObject = new props.data.data();
  const context = useContext(FormContext);

  useEffect(() => {
    generateForm();
    context.events.addEventListener('change', parseChange);
    return () => {
      context.events.removeEventListener('change', parseChange);
    };
  }, []);

  function parseChange(e) {
    updateForm();
  }

  function checkIfs(field) {
    let show = false;

    if (!field.if) {
      if (context.recordType && field.dataTypes && !field.dataTypes.includes(context.recordType)) {
        show = false;
      } else {
        show = true;
      }
    } else {
      if (field.if.true) {
        let found = false;

        field.if.true.forEach(ifline => {
          let checkvalue = context.getValue(ifline['key'], true);

          if (checkvalue == ifline['value']) {
            found = true;
          }
        });

        if (found == true) {
          show = true;
        } else {
          show = false;
        }
      }
      if (field.if.false) {
        let found = false;

        field.if.false.forEach(ifline => {
          let checkvalue = context.getValue(ifline['key'], true);

          if (checkvalue == ifline['value']) {
            found = true;
          }
        });

        if (found == true) {
          show = false;
        } else {
          show = true;
        }
      }
      if (context.recordType && field.dataTypes && !field.dataTypes.includes(context.recordType)) {
        show = false;
      }
    }
    return show;
  }

  function updateForm() {
    dataObject.fields.forEach(field => {
      let fieldToUpdate = field.nested ? field.subField : field.field;
      if (field.edit !== false || (props.editID === 0 && field.create) || field.view) {
        if (checkIfs(field)) {
          context.setVisible(fieldToUpdate, true);
        } else {
          context.setVisible(fieldToUpdate, false);
        }
      }
    });
  }

  function generateForm() {
    const fields = Fields();
    const generatedForm = [];
    let cnt = 0;
    dataObject.fields.forEach(field => {
      if (field.edit || (props.editID == 0 && field.create) || field.view) {
        let FieldType = fields[field.type];
        generatedForm.push(<FieldType key={field.name + cnt} data={field} editID={props.editID}></FieldType>);
        cnt++;
      }
    });

    setFormHTML(generatedForm);
  }

  return <>{formHTML}</>;
};

export default FormGenerator;
