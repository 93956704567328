export default class FormManager {
  constructor(data) {
    this.row = {};
    this.data = data;
    this.values = {};
    this.visibles = {};
    this.recordType = null;
    this.fields = data.fields;
    this.events = document.createElement('div');
  }

  checkForm() {
    let alertcontent = 'There are some issues with your form\n';
    let success = true;

    if (this.data.multiType && !this.recordType) {
      alertcontent += '- Type is required\n';
      success = false;
    }

    for (const key in this.values) {
      let field = this.values[key];
      if (field.dataTypes && this.recordType && !field.dataTypes.includes(this.recordType)) {
        continue;
      } else if (!field.valid) {
        console.log('ERROR IN ', field);
        alertcontent += '- ' + field.error + '\n';
        success = false;
      }
    }

    if (!success) {
      alert(alertcontent);
    }

    return success;
  }

  setVisible(field, isVisible) {
    if (this.visibles[field] !== isVisible) {
      this.visibles[field] = isVisible;
      let changeEvent = new CustomEvent('isVisible', { detail: { field: field, isVisible: isVisible } });
      this.events.dispatchEvent(changeEvent);
    }
  }

  checkVisible(field) {
    if (!this.visibles[field]) {
      return false;
    } else {
      if (this.visibles[field] == true) {
        return true;
      }
    }
  }

  getValue(fieldname, frombasedata) {
    if (!this.values[fieldname]) {
      if (frombasedata) {
        return this.getValueFromData(fieldname);
      }
      return '';
    } else {
      return this.values[fieldname].value;
    }
  }

  getValueFromData(fieldname) {
    if (this.row[fieldname]) {
      return this.row[fieldname];
    } else {
      return null;
    }
  }

  deleteValue(fieldname) {
    delete this.values[fieldname];
  }

  clear() {
    this.row = [];
    this.fields.forEach(field => {
      let fieldToClear;
      if (field.edit !== false) {
        if (field.nested) {
          fieldToClear = field.subField;
        } else {
          fieldToClear = field.field;
        }
        this.setFieldValue(fieldToClear, '', true, '');
      }
    });
  }

  setData(row) {
    this.row = row;
    this.fields.forEach(field => {
      if (field.edit !== false) {
        if (row[field.field] !== null && row[field.field] !== undefined) {
          if (field.nested) {
            this.setFieldValue(field.subField, row[field.field][field.subField], true, '');
          } else {
            this.setFieldValue(field.field, row[field.field], true, '');
          }
        }
      }
    });
  }

  getFields() {
    let returnObject = {};

    for (const key in this.values) {
      let field = this.values[key];
      returnObject[key] = field.value;
    }
    return returnObject;
  }

  setFieldStatus(field, valid, error) {
    if (this.values[field]) {
      this.values[field].valid = valid;
      this.values[field].error = error;
    }
  }

  setFieldValue(field, value, valid, error, dataTypes) {
    if (this.values[field] === undefined) {
      this.values[field] = { value: '', valid: false, error: '' };
    }

    this.values[field].value = value;
    this.values[field].valid = valid;
    this.values[field].error = error;
    this.values[field].dataTypes = dataTypes;

    let changeEvent = new CustomEvent('change', { detail: { field: field, value: value } });
    this.events.dispatchEvent(changeEvent);
  }

  setRecordType(type) {
    this.recordType = type;
    let changeEvent = new CustomEvent('recordType', { detail: { field: 'recordType', value: type } });
    this.events.dispatchEvent(changeEvent);
  }
}
