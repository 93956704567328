// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_appsync_graphqlEndpoint": "https://slkpace6vfgqvfhejkwuesmihe.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    // "aws_appsync_apiKey": "da2-fw2ymemhhzhijoc7aopbroqnvy",
    "Auth": {
        "region": "eu-central-1",
        "userPoolId": "eu-central-1_jlzLVtIrw",
        "userPoolWebClientId": "1vk9kqbspm91nv4v5f6l08j2l6",
      },
};

export default awsmobile;