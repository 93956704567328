import { rgba, darken } from 'polished';
import React, { useEffect, useContext } from 'react';
import { Link as NavLink } from 'gatsby';
import styled from 'styled-components/macro';
import { Search } from '../../../utils/Search';
import FilterList from '../../List/FilterList';
import DeleteIds from '../../../utils/DeleteIds';
import { TableContext } from '../../../contexts/TableContext';

import {
  Checkbox,
  Grid,
  IconButton,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  ListItem,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import { ArchiveOutlined, Delete as DeleteIcon, FilterList as FilterListIcon } from '@material-ui/icons';
import InsertUpdateRecord from '../../../utils/InsertUpdateRecord';

const queryString = require('query-string');

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Link = styled(ListItem)`
  span {
    color: ${props => rgba(props.theme.table.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.table.color, 0.9)};
  }

  &:hover {
    background-color: ${props => darken(0.015, props.theme.table.background)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.03, props.theme.table.background)};

    span {
      color: ${props => props.theme.table.color};
    }
  }
`;

let flagCells = [];
let headCells = [];
let deepLinks = [];

function generateFlags(data) {
  flagCells = [];

  if (data.flags) {
    data.flags.forEach((flag, index) => {
      flagCells.push({
        ...flag,
        id: index,
        disablePadding: true
      });
    });
  }
}

function generateList(data) {
  headCells = [];
  data.fields.forEach(field => {
    let numeric = false;
    if (field.list == true && !field.nested) {
      headCells.push({
        id: field.field,
        numeric: false,
        disablePadding: false,
        label: field.name,
        dataTypes: field.dataTypes,
        customDisplay: field.customDisplay,
        disabledSorting: field.disabledSorting
      });
    }
  });
  if (data.options.indexOf('archive') !== -1) {
    headCells.push({
      archive: true,
      disablePadding: false,
      id: data.archive.field,
      label: data.archive.label,
      field: data.archive.field,
      dataTypes: data.archive.dataTypes,
      component: data.archive.component
    });
  }
}

function ClearDeepLinks() {
  deepLinks = [];
}

function generateDeepLinks(data, deepSearch) {
  ClearDeepLinks();
  if (data.options.indexOf('deep') !== -1) {
    if (deepSearch) {
      data.subIdList.forEach(deepLink => {
        if (deepLink.displayInDeepSearch) {
          deepLinks.push(deepLink);
        }
      });
    } else {
      deepLinks = data.subIdList;
    }
  }
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disabledSorting}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {deepLinks.map(deep => (
          <TableCell key={deep.id} padding={deep.disablePadding ? 'none' : 'default'}>
            {deep.header}
          </TableCell>
        ))}
        {flagCells.map(flag => (
          <TableCell key={flag.id} padding={flag.disablePadding ? 'none' : 'default'}>
            {flag.header}
          </TableCell>
        ))}
        {props.data.options.includes('delete') ? (
          <TableCell key="delete-header" padding="default">
            Verwijder
          </TableCell>
        ) : (
          ''
        )}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = props => {
  const { numSelected } = props;
  const data = new props.data.data();
  const [filterListVisible, setFilterListVisible] = React.useState(false);

  function OpenFilter(e) {
    setFilterListVisible(!filterListVisible);
  }

  let options = [];
  if (data.options.indexOf('delete') != -1) {
    options.push(
      <Tooltip title="Delete" onClick={() => props.onClickDelete()}>
        <IconButton aria-label="Delete">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Toolbar>
      <div>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle"></Typography>
        )}
      </div>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <>{options}</>
        ) : (
          <>
            {data.filters && (
              <Tooltip title="Filter list" onClick={OpenFilter}>
                <IconButton aria-label="Filter list">
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
            {filterListVisible && <FilterList onFilterUpdated={props.onFilterUpdated} data={data}></FilterList>}
          </>
        )}
      </div>
    </Toolbar>
  );
};

function EnhancedTable(props) {
  const [page, setPage] = React.useState(0);
  const [paginationToken, setPaginationToken] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState();
  const [search, setSearch] = React.useState('');
  const [orderBy, setOrderBy] = React.useState();
  const [dense, setDense] = React.useState(false);
  const [filters, setFilters] = React.useState('');
  const [goBack, setGoBack] = React.useState(false);
  const [rowcount, setRowCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [canOpen, setCanOpen] = React.useState(false);
  const [fullOrder, setFullOrder] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState('');
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deepSearch, setDeepSearch] = React.useState(false);
  const [deepSearchId, setDeepSearchId] = React.useState('');
  const [searchForOne, setSearchForOne] = React.useState(false);
  const [triggerRefresh, setTriggerRefresh] = React.useState(false);

  const dataFetchedRef = React.useRef(false);

  const searchTimeout = React.useRef();

  const dataObject = new props.data.data();
  const rowsPerPageLimit = dataObject.rowsPerPageLimit ?? [10, 25, 50, 100];

  const context = useContext(TableContext);

  let searchParams = {
    queryLimits: {
      page: page,
      perpage: rowsPerPage,
      order: fullOrder === '' ? (dataObject.defaultOrder ? dataObject.defaultOrder : fullOrder) : fullOrder
    },
    search: search,
    filters: filters,
    deepSearchId: deepSearchId,
    searchForOne: searchForOne
  };

  const parseBackLink = () => {
    const queryParams = queryString.parse(window.location.href.split('?')[1]);

    if (Object.keys(queryParams).length > 0) {
      const { back } = queryParams;

      setGoBack({
        to: back
      });

      setDeepSearch(true);
    }
  };

  const updateData = async (paginate = true) => {
    if (!context.deepLink || (context.deepLink && search !== '')) {
      if (paginate && paginationToken && props.nextRequestShouldPaginate) searchParams.queryLimits.paginationToken = paginationToken;
      const data = await Search(dataObject, searchParams);
      setRowCount(data.queryResultInfo.count);
      setPaginationToken(data.queryResultInfo.paginationToken);
      props.handlePaginate();

      setRows(data.rows);
      props.onLoaded();
      context.setLastPageData(undefined);
    }
  };

  useEffect(() => {
    parseBackLink();
    handleLastPageData();
    setCanOpen(dataObject.options.indexOf('edit') !== -1 || dataObject.options.indexOf('view') !== -1);
    handleDeepLinkClick(window.location.href);
    generateDeepLinks(dataObject, deepSearch);
    generateList(dataObject);
    generateFlags(dataObject);
    applyFilters();
    updateData();
    context.setFilters(filters);
  }, [triggerRefresh, props.RefreshCounter, filters]);

  const applyFilters = () => {
    if (filters || !dataObject.filters) return;
    for (let i = 0; i < dataObject.filters.length; i++) {
      const filter = dataObject.filters[i];
      if (filter.applied) setFilters({ [filter.field]: filter.default });
    }
  };

  const handleLastPageData = () => {
    if (context.lastPageData) {
      if (context.lastPageData.filters) setFilters(context.lastPageData.filters);
      if (context.lastPageData.search) setSearchValue(context.lastPageData.search);
      if (context.lastPageData.searchForOne) setSearchForOne(context.lastPageData.searchForOne);
      if (context.lastPageData.deepSearchId) setDeepSearchId(context.lastPageData.deepSearchId);
      if (context.lastPageData.queryLimits) {
        if (context.lastPageData.queryLimits.fullOrder) setFullOrder(context.lastPageData.queryLimits.fullOrder);
        if (context.lastPageData.queryLimits.page) setPage(context.lastPageData.queryLimits.page);
        if (context.lastPageData.queryLimits.perpage) setRowsPerPage(context.lastPageData.queryLimits.perpage);
      }
      searchParams = context.lastPageData;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let neworder = isAsc ? 'desc' : 'asc';
    setOrder(neworder);
    setOrderBy(property);
    setFullOrder(property + ' ' + neworder);
    setTriggerRefresh(!triggerRefresh);
  };

  const handleSelectAllClick = (props, event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n[dataObject.id]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const openRecord = (event, id) => {
    props.OpenRecord(id);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    searchParams.queryLimits.page = newPage;
    if (paginationToken) searchParams.queryLimits.paginationToken = paginationToken;
    setTriggerRefresh(!triggerRefresh);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationToken(null);
    setTriggerRefresh(!triggerRefresh);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const updateSearch = input => {
    setPaginationToken(null);
    if (!deepSearch) {
      setSearchForOne(false);
      setDeepSearchId('');
    }

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      setPage(0);
      setSearch(input);
      setTriggerRefresh(!triggerRefresh);
    }, 250);
  };

  const handleSearchInput = event => {
    const value = event.target.value;
    dataFetchedRef.current = false;
    setSearchValue(value);
    updateSearch(value);
  };

  const handleDeleteClose = event => {
    setDeleteOpen(false);
  };

  const onFilterUpdated = filters => {
    setPage(0);
    setPaginationToken(null);
    if (dataObject.new) {
      setFilters(filters);
    } else {
      setFilters(JSON.stringify(filters));
    }
  };

  const deleteRecord = async event => {
    setDeleteOpen(false);
    await DeleteIds(dataObject, selected);
    setSelected([]);
    updateData(false);
  };

  const deleteRepeatingRecords = async event => {
    setDeleteOpen(false);
    await DeleteIds(dataObject, selected, true);
    setSelected([]);
    updateData(false);
  };

  const makeDeepLinkHref = (link, row) => {
    let modifiedLink = '';
    if (link.linkInRow) {
      return row[link.field];
    } else {
      if (link.nested) {
        if (Array.isArray(row[link.field])) {
          modifiedLink += `${link.link.replace(link.id, row[link.field][0][link.id])}`;
        } else {
          modifiedLink += `${link.link.replace(link.id, row[link.field][link.id])}`;
        }
      } else {
        modifiedLink += `${link.link.replace(link.id, row[link.id])}`;
      }

      const deepLinkData = {
        queryLimits: {
          page: page,
          perpage: rowsPerPage,
          order: fullOrder === '' ? (dataObject.defaultOrder ? dataObject.defaultOrder : fullOrder) : fullOrder
        },
        search: search,
        filters: filters,
        deepSearchId: deepSearchId,
        searchForOne: searchForOne
      };

      modifiedLink += `&back=${window.location.pathname}&data=${JSON.stringify(deepLinkData)}`;

      return modifiedLink;
    }
  };

  /**
   * Deep links are nested in a table row
   * @param {Object} event The click event object
   */
  const handleDeepLinkClick = href => {
    const queryParams = queryString.parse(href.split('?')[1]);
    context.setDeepLink(false);

    if (Object.keys(queryParams).length !== 0 && deepSearch === false) {
      context.setDeepLink(true);
      if (queryParams.subIdField) {
        for (let i = 0; i < deepLinks.length; i++) {
          const currentDeepLink = deepLinks[i];
          if (currentDeepLink.field === queryParams.subIdField) {
            setDeepSearchId(currentDeepLink.deepSearchId);
            break;
          }
        }
      }

      setSearchForOne(true);
      setSearch(queryParams.subId);
      setDeepSearch(true);
      setTriggerRefresh(!triggerRefresh);
    }
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const handleArchive = async (e, d, row) => {
    e.stopPropagation();

    const archive = await InsertUpdateRecord(dataObject, [], row.id);

    if (archive.result) {
      await updateData();
    }
  };

  const deepSearchTitle = () => {
    if (dataObject.deepTitle) {
      return dataObject.generateDeepTitle(rows);
    }
  };

  return (
    <div>
      <Paper>
        <Dialog open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Delete'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Weet je zeker dat je deze items willen verwijderen?</DialogContentText>
          </DialogContent>
          {dataObject.repeating ? (
            <DialogActions>
              <Button onClick={handleDeleteClose} color="primary">
                Nee
              </Button>
              <Button onClick={deleteRepeatingRecords} color="primary" autoFocus>
                Ja, ik wil deze en alle opvolgende items verwijderen!
              </Button>
              <Button onClick={deleteRecord} color="primary" autoFocus>
                Ja, ik wil alleen deze items verwijderen!
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button onClick={handleDeleteClose} color="primary">
                Nee
              </Button>
              <Button onClick={deleteRecord} color="primary" autoFocus>
                Ja!
              </Button>
            </DialogActions>
          )}
        </Dialog>
        {!deepSearch && (
          <Grid item xs={12}>
            <Box p={2}>
              <TextField
                value={searchValue}
                label={'Zoek ' + dataObject.nameSingle}
                variant="outlined"
                fullWidth
                onChange={handleSearchInput}
              />
            </Box>
          </Grid>
        )}
        <Divider />
        <EnhancedTableToolbar
          onFilterUpdated={onFilterUpdated}
          onClickDelete={() => setDeleteOpen(true)}
          {...props}
          numSelected={selected.length}
        />
        {deepSearch && (
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              context.setDeepLink(false);
              const queryParams = queryString.parse(window.location.href.split('?')[1]);
              const { data } = queryParams;
              context.setLastPageData(data);
            }}
          >
            <Link component={NavLink} to={goBack.to} activeClassName="active">
              Ga terug
            </Link>
          </Button>
        )}
        {deepSearch ? <span>{deepSearchTitle()}</span> : ''}
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
            <EnhancedTableHead
              {...props}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={e => {
                handleSelectAllClick(props, e);
              }}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              paginationToken={paginationToken}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row[dataObject.id]);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={canOpen ? event => openRecord(event, row[dataObject.id]) : null}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row[dataObject.id] + '-row'}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        key={row[dataObject.id] + '-cell'}
                        onClick={event => {
                          handleClick(event, row[dataObject.id]);
                          event.stopPropagation();
                        }}
                      />
                    </TableCell>

                    {headCells.map(function (d, subindex) {
                      let key = 'subcell-' + d.id + '-' + index + '-' + subindex;
                      let displayValue = row[d.id];

                      if (d.customDisplay) {
                        displayValue = d.customDisplay(row);
                      }

                      if (d.id == 'name') {
                        return (
                          <TableCell style={dataObject.getCellStyle ? dataObject.getCellStyle(row) : {}} align="left" key={key}>
                            {displayValue}
                          </TableCell>
                        );
                      } else if (d.archive && !row[d.field]) {
                        return (
                          <TableCell padding="checkbox" onClick={event => handleArchive(event, d, row)}>
                            <ArchiveOutlined style={{ cursor: 'pointer' }} />
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell align="left" key={key}>
                            {displayValue}
                          </TableCell>
                        );
                      }
                    })}

                    {deepLinks.map(function (deepLink, index) {
                      let deepDisplayValue;
                      let key = `subcell-${deepLink.id}-${index}`;
                      if (typeof deepLink.name == 'function') {
                        deepDisplayValue = deepLink.name(row);
                      } else if (typeof deepLink.name == 'object') {
                        const ReactComponent = deepLink.name;
                        deepDisplayValue = <ReactComponent />;
                      } else {
                        deepDisplayValue = deepLink.name;
                      }

                      if (deepLink.link || deepLink.linkInRow) {
                        deepDisplayValue = (
                          <Link button dense component={NavLink} to={makeDeepLinkHref(deepLink, row)} activeClassName="active">
                            {deepDisplayValue}
                          </Link>
                        );
                      } else {
                        deepDisplayValue = <span>{deepDisplayValue}</span>;
                      }

                      if (row.type && dataObject.multiType) {
                        if (deepLink.dataTypes.includes(row.type)) {
                          return (
                            <TableCell
                              onClick={event => {
                                event.stopPropagation();
                              }}
                              key={key}
                            >
                              {deepDisplayValue}
                            </TableCell>
                          );
                        } else
                          return (
                            <TableCell
                              onClick={event => {
                                event.stopPropagation();
                              }}
                              key={key}
                            >
                              -
                            </TableCell>
                          );
                      } else {
                        return (
                          <TableCell
                            onClick={event => {
                              event.stopPropagation();
                            }}
                            key={key}
                          >
                            {deepDisplayValue}
                          </TableCell>
                        );
                      }
                    })}

                    {flagCells.map((flag, index) => {
                      let key = `subcell-${flag.id}-${index}`;

                      return (
                        <TableCell
                          onClick={event => {
                            event.stopPropagation();
                          }}
                          key={key}
                        >
                          {flag.value(row[flag.field])}
                        </TableCell>
                      );
                    })}

                    {props.data.options.includes('delete') ? (
                      <TableCell
                        onClick={event => {
                          event.stopPropagation();
                        }}
                        key={`delete-icon-${row[dataObject.id]}`}
                      >
                        <Tooltip
                          title="Delete"
                          onClick={e => {
                            setSelected([row[dataObject.id]]);
                            setDeleteOpen(true);
                          }}
                        >
                          <IconButton aria-label="Delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      ''
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageLimit}
          component="div"
          count={paginationToken ? -1 : rowcount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
    </div>
  );
}

function AdvancedTable(props) {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable {...props} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdvancedTable;
