import styled from 'styled-components/macro';
import ReactHlsPlayer from 'react-hls-player';
import { Box, CircularProgress } from '@material-ui/core';
import { FormContext } from '../../../contexts/FormContext';
import React, { useEffect, useState, useContext } from 'react';

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

function m3u8(value) {
  if (Array.isArray(value)) {
    const children = [];
    for (let index = value.length - 1; index >= 0; index--) {
      const element = value[index];
      children.push(<ReactHlsPlayer src={element} autoPlay={false} controls={true} width="100%" height="auto" />);
    }
    return <Box>{children}</Box>;
  } else return <ReactHlsPlayer src={value} autoPlay={false} controls={true} width="100%" height="auto" />;
}

function video(value) {
  return (
    <video width="100%" height="auto" controls autoPlay>
      <source type="video/webm" src={value}></source>
    </video>
  );
}

function noVideo() {
  return <span>Er zijn geen opnames beschikbaar</span>;
}

const components = {
  m3u8: m3u8,
  video: video,
  noVideo: noVideo
};

const M3U8Player = props => {
  const context = useContext(FormContext);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [component, setComponent] = useState('noVideo');

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  function createVideoDisplayComponent() {
    if (value && value !== '') {
      if (value.indexOf('.m3u8') !== -1 || Array.isArray(value)) {
        setComponent('m3u8');
      } else {
        setComponent('video');
      }
    } else {
      setComponent('noVideo');
    }
    setLoading(false);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);
    let newvalue = context.getValue(props.data.field);
    setValue(newvalue);
    let checked = checkValue(newvalue);
    context.setFieldStatus(props.data.field, checked.result, checked.error);
    setIsVisible(context.checkVisible(props.data.field));
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  useEffect(() => {
    checkAndUpdateValue(value);
    createVideoDisplayComponent();
  }, [value]);

  function externalValueUpdated(field, externalvalue) {
    if (field == props.data.field && value != externalvalue) {
      setValue(externalvalue);
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if (value.length == 0) {
        result = false;
        error = props.data.name + ' is required';
      } else {
        result = true;
      }
    }

    if (!completed) {
      result = false;
      error = 'File is still uploading, please wait';
    }
    return { result: result, error: error };
  }

  function checkAndUpdateValue(val) {
    let checked = checkValue(val);
    context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
  }

  return (
    <>
      {isVisible && (
        <>
          <Container>{loading ? <CircularProgress /> : components[component](value)}</Container>
        </>
      )}
    </>
  );
};

export default M3U8Player;
