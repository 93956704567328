import * as types from '../../constants';
import { Auth } from 'aws-amplify';
import Config from '../../config/Config';

export function signIn(credentials) {
  return async dispatch => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    console.log('credentials ', credentials);
    let loginvalue = credentials.email;

    if (Config.loginType === 'username') {
      loginvalue = credentials.username;
    }

    return Auth.signIn(loginvalue, credentials.password)
      .then(response => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          user: response
        });

        return response;
      })
      .catch(error => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async dispatch => {
    dispatch({
      type: types.AUTH_SIGN_OUT
    });

    return Auth.signOut({ global: true });
  };
}

export function resetPassword(credentials) {
  return async dispatch => {
    return Auth.forgotPassword(credentials.email)
      .then(response => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_REQUEST,
          email: credentials.email
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function resetPasswordSubmit(credentials) {
  return async dispatch => {
    return Auth.forgotPasswordSubmit(credentials.email, credentials.code, credentials.password)
      .then(response => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS
        });
      })
      .catch(error => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function completeNewPassword(user, credentials) {
  return async dispatch => {
    dispatch({ type: types.AUTH_CHANGE_PASSWORD_REQUEST });

    return Auth.completeNewPassword(user, credentials.newPassword)
      .then(response => {
        dispatch({
          type: types.AUTH_CHANGE_PASSWORD_SUCCESS
        });
      })
      .catch(error => {
        dispatch({ type: types.AUTH_CHANGE_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function currentSession() {
  return async dispatch => {
    return Auth.currentSession()
      .then(response => {
        return Auth.currentUserPoolUser();
      })
      .then(response => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          user: response
        });

        return true;
      })
      .catch(error => {
        return false;
      });
  };
}
