import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Button as MuiButton,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Paper as MuiPaper,
  Select,
  FormGroup,
  Typography,
  FormLabel,
  FormControlLabel
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import styled from 'styled-components/macro';
import { FormContext } from '../../../contexts/FormContext';
import GetOptions from '../../../utils/cms/GetOptions';

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
`;

const CheckBoxes = props => {
  const context = useContext(FormContext);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const checkedOptions = useRef({});
  const [parsedOptions, setParsedOptions] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);

    let field;

    if (props.data.nested) {
      field = props.data.subField;
    } else {
      field = props.data.field;
    }

    let newvalue = context.getValue(field);
    if (newvalue == '') {
      newvalue = null;
      setValue(newvalue);
      let checked = checkValue(newvalue);
      context.setFieldValue(field, null, checked.result, checked.error, props.data.dataTypes);
    } else {
      setValue(newvalue);
      let checked = checkValue(newvalue);
      context.setFieldStatus(field, checked.result, checked.error);
    }

    //console.log("DEBUG 1");

    makeOptions();
    context.events.addEventListener('isVisible', parseVisible);
    setIsVisible(context.checkVisible(field));
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  function getChecked(id) {
    if (!value) {
      return false;
    }
    let res = value.indexOf(id);

    //console.log(id,res,value.length);
    if (res != -1) {
      return true;
    }
    return false;
  }

  async function makeOptions() {
    if (props.data.options) {
      if (!parsedOptions) {
        let parsedoptions = await GetOptions(props.data.options);
        setParsedOptions(parsedoptions);
        // console.log("parsedOptions.current",parsedOptions.current);
        buildOptions();
      } else {
        buildOptions();
      }
    }
  }

  function buildOptions() {
    if (!parsedOptions) {
      return;
    } else {
      //console.log("parsedoptions",parsedOptions);
    }
    let HTMLOptions = [];
    parsedOptions.forEach(option => {
      HTMLOptions.push(
        <FormControlLabel
          key={option.key + '-' + option.value + '1'}
          control={
            <Checkbox key={option.key + '-' + option.value + '2'} checked={getChecked(option.key)} onChange={valueUpdated} name={option.key} />
          }
          label={option.value}
        />
      );
    });
    setOptions(HTMLOptions);
  }

  useEffect(() => {
    checkAndUpdateValue(value);

    if (value) {
      for (let i = 0; i < value.length; i++) {
        let current = value[i];
        checkedOptions.current[current] = true;
      }
    }
    //console.log("DEBUG3",parsedOptions,value);
    makeOptions();
  }, [value, parsedOptions]);

  function externalValueUpdated(field, externalvalue) {
    // console.log('external value to be updated', field, externalvalue, props.data.field, props.data);
    if (props.data.nested) {
      if (field === props.data.subField && value != externalvalue) {
        // console.log('external value updated set nested value', field, value, externalvalue);
        setValue(externalvalue);
      }
    } else {
      if (field === props.data.field && value != externalvalue) {
        // console.log('external value updated set value', field, value, externalvalue);
        setValue(externalvalue);
      }
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if (value) {
        result = false;
        error = props.data.name + ' is required';
      } else {
        result = true;
      }
    }
    return { result: result, error: error };
  }

  function checkAndUpdateValue(val) {
    //console.log("Update value ",props.data.field,val);
    let checked = checkValue(val);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, val, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
    }
  }

  function makeArray() {
    let list = [];
    for (let variable in checkedOptions.current) {
      if (checkedOptions.current[variable] == true) {
        let val = variable;
        if (props.data.keyType == 'int') {
          val = parseInt(val);
        }

        list.push(parseInt(variable));
      }
    }
    return list;
  }

  const valueUpdated = event => {
    let key = event.target.name;
    checkedOptions.current[key] = event.target.checked;
    let list = makeArray();
    let checked = checkValue(list);
    if (props.data.nested) {
      context.setFieldValue(props.data.subField, list, checked.result, checked.error, props.data.dataTypes);
    } else {
      context.setFieldValue(props.data.field, list, checked.result, checked.error, props.data.dataTypes);
    }
  };

  return (
    <>
      {isVisible && (
        //     <FormControl m={2}>
        //     <InputLabel id={"label-"+props.data.field}>{props.data.name}</InputLabel>
        //     <Select
        //       labelId={"label-"+props.data.field}
        //       value={value}
        //       fullWidth
        //       label={props.data.name}
        //       onChange={valueUpdated}
        //     >
        //       {options}
        //     </Select>
        //   </FormControl>

        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">{props.data.name}</FormLabel>
          <FormGroup>{options}</FormGroup>
        </FormControl>
      )}
    </>
  );
};

export default CheckBoxes;
