import { API, graphqlOperation } from 'aws-amplify';

async function checkMediaConversionStatus(jobIds) {
  let query = `query checkMediaConversionStatus {
  checkMediaConversionStatus(jobIds: ["${jobIds.join('","')}"]) {
    url
    jobId
    signedUrl
    convertedLocation
  }
}`;

  const res = await API.graphql(graphqlOperation(query, {})).catch(error => {
    console.log('GRAPHQL ERROR', error);
    alert('oeps! ', error);
  });

  return res.data.checkMediaConversionStatus;
}

export default checkMediaConversionStatus;
