function ImageFitter(
  fitfill,
  containerWidth,
  containerHeight,
  width,
  height,
  position
) {
  if (!position) {
    position = 'center'
  }
  let newwidth = 0
  let newheight = 0
  let cWidth = containerWidth;
  let cHeight = containerHeight;


  let ret = { height: height, width: width, x: 0, y: 0, xScale: 1, yScale: 0 }
  if (fitfill == 'fill') {
    let factor1 = containerWidth / containerHeight
    let factor2 = width / height
    if (factor1 > factor2) {
      newwidth = containerWidth
      newheight = (containerWidth / width) * height
    } else {
      newwidth = (containerHeight / height) * width
      newheight = containerHeight
    }
  } else if (fitfill == 'fit') {
    let factor1 = containerWidth / containerHeight
    let factor2 = width / height
    if (factor1 < factor2) {
      newwidth = containerWidth
      newheight = (containerWidth / width) * height
    } else {
      newwidth = (containerHeight / height) * width
      newheight = containerHeight
    }
  }
  else if (fitfill == "width") {
    let factor1 = containerWidth / width;
    newwidth = containerWidth;
    newheight = height * factor1;
    cWidth = containerWidth;
    cHeight = newheight;
  }
  else if (fitfill == "height") {
    let factor1 = containerHeight / height;
    newwidth = containerHeight;
    newheight = width * factor1;
    cWidth = newwidth;
    cHeight = containerHeight;
  }

  

  ret.width = newwidth
  ret.height = newheight
  ret.x = (containerWidth - newwidth) / 2
  ret.y = (containerHeight - newheight) / 2

  if (position.indexOf('left') != -1) {
    ret.x = 0
  } else if (position.indexOf('right') != -1) {
    ret.x = containerWidth - newwidth
  }

  if (position.indexOf('bottom') != -1) {
    ret.y = containerHeight - newheight
  } else if (position.indexOf('top') != -1) {
    ret.y = 0
  }

  if (fitfill == "width" || fitfill == "height") {
    ret.x = 0;
    ret.y = 0;
  }

  ret.xScale = newwidth / width
  ret.yScale = newheight / height
  ret.containerHeight = cHeight;
  ret.containerWidth = cWidth;

  console.log("ret ",ret);

  if (isNaN(ret.width) || isNaN(ret.height)){
    return false;
  }

  return ret
}
export default ImageFitter
