const SharedData = require('./SharedData');

module.exports = class Categories extends SharedData {
  constructor(options = {}) {
    super({
      id: 'id',
      nameField: 'title',
      nameSingle: 'category',
      nameMultiple: 'categories',
      apiCallReturnSubtype: 'categories',
      options: [],
      search: {
        searchValues: {
          title: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
            id
            title
        }`
      }
    });

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Name',
        field: 'title',
        type: 'TextInput',
        required: true,
        list: true
      }
    ];
  }
};
