const SharedData = require('./SharedData');
const { getEnvironmentVars } = require('../Config');
module.exports = class User extends SharedData {
  constructor(options = {}) {
    super({
      id: 'id',
      nameField: 'user_name',
      nameSingle: 'gebruiker',
      nameMultiple: 'gebruikers',
      apiCallReturnSubtype: 'users',
      options: ['edit', 'delete', 'insert', 'deep', 'export'],
      search: {
        searchValues: {
          email: 'string',
          lastname: 'string',
          firstname: 'string',
          user_name: 'string'
        }
      },
      export: {
        apiCall: 'initiateUserExport'
      },
      get: {
        apiReturnStructure: options.apiSelection
          ? options.apiSelection
          : `{
          id
          firstname
          lastname
          email
          registration
          preposition
          user_name
          display_name
          city
          phone
          question1
          question2
          question3
          image
          is_invisible
          is_banned
          is_busy
          chat_allowed
          type
          in_export
          created_at
          profile {
            sex
            birthdate
            category
            handicap_physically
            handicap_psychologically
            handicap_mentally
            handicap_visually
            handicap_auditory
            tools
            mental_age
            hospital
            school
            institution
            referer
            customReferer
            parent_firstname
            parent_preposition
            parent_lastname
            parent_phone
            parent_email
            parent_street
            parent_nr
            parent_nr_extra
            parent_zipcode
            parent_city
            has_appdate
            has_newsletter
            created_at
            updated_at
          }
          clownProfile {
            is_visible
            id
            has_webcam
            video_busy_id
            video_id
            autoreply_full
            autoreply_offline
            reply1_title
            reply1_body
            reply2_title
            reply2_body
            reply3_title
            reply3_body
            has_chat
            big_image
            details
            is_favorite
          }
          settings {
            message_clown
            message_child
            message_custom
            message_service
            message_new_show
            message_videonew
            message_videoclown
            message_child_online
            message_clown_online
            message_videofavorite
          }
        }`
      },
      update: {
        apiCall: 'updateUser',
        apiCallReturnSubtype: null,
        apiInput: `id: idValue, input: { inputStructure }`,
        apiReturnStructure: `{
          id
          firstname
          lastname
          email
        }`
      },
      delete: {
        apiCall: 'deleteClowns'
      }
    });

    if (options && options.constants) {
      this.inputConstants = {
        ...this.inputConstants,
        ...options.constants
      };
    }

    this.defaultOrder = 'created_at desc';

    this.multiType = true;
    this.multiTypeId = 'type';
    this.types = {
      name: 'Type',
      field: 'recordType',
      options: [
        {
          value: 'Kind',
          key: 'child'
        },
        {
          value: 'Clown',
          key: 'clown'
        },
        {
          value: 'Zorginstelling',
          key: 'institute'
        },
        {
          value: 'Medewerker',
          key: 'employee'
        },
        {
          value: 'Gast',
          key: 'guest'
        },
        {
          value: 'Test',
          key: 'test'
        }
      ]
    };

    this.subidname = 'name';
    this.subIdList = [
      {
        nested: true,
        name: 'Primary Video',
        id: 'video_id',
        field: 'clownProfile',
        link: '/app/videos?subId=video_id&subIdField=clownProfile',
        dataTypes: ['clown']
      },
      {
        nested: true,
        name: 'Druk Video',
        id: 'video_busy_id',
        field: 'clownProfile',
        link: '/app/videos?subId=video_busy_id&subIdField=clownProfile',
        dataTypes: ['clown']
      },
      {
        id: 'id',
        nested: false,
        field: 'notes',
        name: 'Opmerkingen',
        deepSearchId: 'user_id',
        link: '/app/opmerkingen?subId=id&subIdField=notes',
        dataTypes: ['child']
      }
    ];

    this.modifyOnInsertUpdate = {
      image: this.modifyImagePath,
      mental_age: this.castToNumber,
      category: this.modifyCategory
    };

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Type',
        field: 'type',
        required: true,
        list: true,
        edit: true,
        type: 'DropDown',
        options: [
          {
            value: 'Kind',
            key: 'child'
          },
          {
            value: 'Clown',
            key: 'clown'
          },
          {
            value: 'Zorginstelling',
            key: 'institute'
          },
          {
            value: 'Medewerker',
            key: 'employee'
          },
          {
            value: 'Gast',
            key: 'guest'
          },
          {
            value: 'Test',
            key: 'test'
          }
        ]
      },
      {
        name: 'Naam',
        field: 'name',
        type: 'TextField',
        required: false,
        list: true,
        edit: false
      },
      {
        name: 'Gebruikersnaam',
        field: 'user_name',
        type: 'TextField',
        list: true,
        required: true,
        edit: true
      },
      {
        name: 'Weergavenaam',
        field: 'display_name',
        type: 'TextField',
        list: false,
        required: true,
        edit: true
      },
      {
        name: 'Voornaam',
        field: 'firstname',
        type: 'TextField',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Tussenvoegsel',
        field: 'preposition',
        type: 'TextField',
        required: false,
        list: false,
        edit: true
      },
      {
        name: 'Achternaam',
        field: 'lastname',
        type: 'TextField',
        list: false,
        edit: true
      },
      {
        name: 'E-mail',
        field: 'email',
        type: 'TextField',
        list: true,
        edit: true
      },
      {
        name: 'Anngemeld door',
        field: 'created_at',
        type: 'TextField',
        list: true,
        edit: false
      },
      {
        name: 'Wachtwoord',
        field: 'password',
        type: 'TextField',
        list: false,
        edit: true,
        createRequired: true
      },
      {
        name: 'Registratie status',
        field: 'registration',
        type: 'DropDown',
        list: true,
        edit: true,
        required: true,
        options: [
          {
            value: 'Gesloten',
            key: 'closed'
          },
          {
            value: 'Geaccepteerd',
            key: 'accepted'
          },
          {
            value: 'Overleden',
            key: 'deceased'
          },
          {
            value: 'Afgekeurd',
            key: 'rejected'
          },
          {
            value: 'In afwachting',
            key: 'pending'
          }
        ],
        customDisplay: this.translate
      },
      {
        name: 'Mag Chatten',
        field: 'chat_allowed',
        type: 'DropDown',
        required: true,
        list: false,
        edit: true,
        options: [
          {
            value: 'Mag Met Niemand Chatten',
            key: 0
          },
          {
            value: 'Mag Alleen Met Clowns Chatten',
            key: 2
          },
          {
            value: 'Mag Met Iedereen Chatten',
            key: 1
          }
        ]
      },
      {
        name: 'Plaats',
        field: 'city',
        type: 'TextField',
        list: false,
        edit: true
      },
      {
        name: 'Telefoon',
        field: 'phone',
        type: 'TextField',
        list: false,
        edit: true
      },
      {
        name: 'Foto',
        field: 'image',
        type: 'ImageUpload',
        required: false,
        list: false,
        edit: true,
        uploadFolder: this.createImageUploadFolder(),
        apiType: 'CMSImage',
        defaultImage: 'https://s3.eu-central-1.amazonaws.com/version2.appstorage.cliniclowns.nl/production/images/default.jpg'
      },
      {
        name: 'Vraag 1',
        field: 'question1',
        type: 'TextField',
        list: false,
        edit: true
      },
      {
        name: 'Vraag 2',
        field: 'question2',
        type: 'TextField',
        list: false,
        edit: true
      },
      {
        name: 'Vraag 3',
        field: 'question3',
        type: 'TextField',
        list: false,
        edit: true
      },
      {
        name: 'Meenemen in export',
        field: 'in_export',
        type: 'DropDown',
        list: false,
        edit: true,
        required: false,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Geslacht',
        dataTypes: ['child'],
        type: 'DropDown',
        field: 'profile',
        subField: 'sex',
        options: [
          {
            value: 'Mannelijk',
            key: 'm'
          },
          {
            value: 'Vrouwelijk',
            key: 'f'
          },
          {
            value: 'Anders',
            key: null
          }
        ]
      },
      {
        edit: true,
        list: false,
        required: false,
        nested: true,
        name: 'Geboortedatum',
        dataTypes: ['child'],
        type: 'TextField',
        field: 'profile',
        input: 'date',
        subField: 'birthdate'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Categorie',
        dataTypes: ['child'],
        type: 'CheckBoxes',
        field: 'profile',
        subField: 'category',
        options: [
          {
            value: '1',
            key: 1
          },
          {
            value: '2',
            key: 2
          },
          {
            value: '3',
            key: 3
          },
          {
            value: '4',
            key: 4
          },
          {
            value: '5',
            key: 5
          }
        ]
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Verstandelijke leeftijd',
        dataTypes: ['child'],
        type: 'TextField',
        field: 'profile',
        input: 'number',
        subField: 'mental_age'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Gehoord van app',
        dataTypes: ['child'],
        type: 'DropDown',
        field: 'profile',
        subField: 'referer',
        default: 1,
        options: [
          {
            key: 1,
            value: 'Via de school/kdc, logeerhuis, woonvoorziening, revalidatiecentrum'
          },
          {
            key: 2,
            value: 'Via via /mond-tot-mondreclame te maken'
          },
          {
            key: 3,
            value: 'Via social media'
          },

          {
            key: 4,
            value: 'Via een poster in het ziekenhuis'
          },
          {
            key: 5,
            value: 'Via de clown in het ziekenhuis'
          },
          {
            key: 6,
            value: 'Via het zorgpersoneel in het ziekenhuis'
          },
          {
            key: 7,
            value: 'Via de pers/commercial'
          },
          {
            key: 8,
            value: 'Via het donateursblad of nieuwsbrief van CliniClowns'
          },
          {
            key: 9,
            value: 'Gegoogled'
          },
          {
            key: 10,
            value: 'Via jullie eigen site'
          },
          {
            key: 11,
            value: 'Via een andere site'
          },
          {
            key: 12,
            value: 'Via een CliniClowns evenement'
          },
          {
            key: 13,
            value: 'Via een nieuwsbrief'
          },
          {
            key: 14,
            value: this.getRefererValue
          }
        ]
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Voornaam Ouder',
        dataTypes: ['child'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_firstname'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Voozetsel Ouder',
        dataTypes: ['child'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_preposition'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Achternaam Ouder',
        dataTypes: ['child'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_lastname'
      },
      {
        edit: true,
        list: false,
        nested: true,
        required: false,
        name: 'Telefoon Ouder',
        dataTypes: ['child'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_phone'
      },
      {
        edit: true,
        list: false,
        nested: true,
        required: false,
        name: 'Email Ouder',
        dataTypes: ['child'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_email',
        input: 'email'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Straatnaam Ouder',
        dataTypes: ['child', 'institute'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_street'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Huisnummer Ouder',
        dataTypes: ['child', 'institute'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_nr'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Huisnummer Ouder Toevoeging',
        dataTypes: ['child', 'institute'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_nr_extra',
        default: ''
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Postcode Ouder',
        dataTypes: ['child', 'institute'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_zipcode'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Woonplaats Ouder',
        dataTypes: ['child', 'institute'],
        type: 'TextField',
        field: 'profile',
        subField: 'parent_city'
      },
      {
        edit: true,
        list: false,
        nested: true,
        required: false,
        name: 'Wilt de CliniClowns Appdate ontvangen',
        dataTypes: ['child'],
        type: 'DropDown',
        field: 'profile',
        subField: 'has_appdate',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        edit: true,
        list: false,
        nested: true,
        required: false,
        name: 'Wilt de CliniClowns Nieuwsbrief ontvangen',
        type: 'DropDown',
        field: 'profile',
        dataTypes: ['child'],
        subField: 'has_newsletter',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Toon in lijst',
        dataTypes: ['clown'],
        field: 'clownProfile',
        subField: 'is_visible',
        nested: true,
        type: 'DropDown',
        list: false,
        edit: true,
        default: 1,
        options: [
          {
            value: 'Ja',
            key: 1
          },
          {
            value: 'Nee',
            key: 0
          }
        ]
      },
      {
        name: 'Is Gebanned',
        field: 'is_banned',
        type: 'DropDown',
        list: false,
        edit: true,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Webcam',
        dataTypes: ['clown'],
        type: 'DropDown',
        field: 'clownProfile',
        subField: 'has_webcam',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Auto Reply Vol',
        dataTypes: ['clown'],
        type: 'TextField',
        field: 'clownProfile',
        subField: 'autoreply_full'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Auto Reply Offline',
        dataTypes: ['clown'],
        type: 'TextField',
        field: 'clownProfile',
        subField: 'autoreply_offline'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Antwoord 1 Title',
        dataTypes: ['clown'],
        type: 'TextField',
        field: 'clownProfile',
        subField: 'reply1_title'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Antwoord 1',
        dataTypes: ['clown'],
        type: 'TextField',
        field: 'clownProfile',
        subField: 'reply1_body'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Antwoord 2 Title',
        dataTypes: ['clown'],
        type: 'TextField',
        field: 'clownProfile',
        subField: 'reply2_title'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Antwoord 2',
        dataTypes: ['clown'],
        type: 'TextField',
        field: 'clownProfile',
        subField: 'reply2_body'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Antwoord 3 Title',
        dataTypes: ['clown'],
        type: 'TextField',
        field: 'clownProfile',
        subField: 'reply3_title'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Antwoord 3',
        dataTypes: ['clown'],
        type: 'TextField',
        field: 'clownProfile',
        subField: 'reply3_body'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Details',
        type: 'TextField',
        field: 'clownProfile',
        dataTypes: ['clown'],
        subField: 'details'
      },
      {
        edit: true,
        list: false,
        nested: true,
        name: 'Chatten Beschikbaar',
        type: 'DropDown',
        field: 'clownProfile',
        subField: 'has_chat',
        dataTypes: ['clown'],
        default: 1,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie bericht van clown',
        nested: true,
        field: 'settings',
        subField: 'message_clown',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie bericht van kind',
        nested: true,
        field: 'settings',
        subField: 'message_child',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie algemene berichten',
        nested: true,
        field: 'settings',
        subField: 'message_service',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie nieuwe show',
        nested: true,
        field: 'settings',
        subField: 'message_new_show',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie nieuwe video',
        nested: true,
        field: 'settings',
        subField: 'message_videonew',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie nieuwe video van favoriete clown',
        nested: true,
        field: 'settings',
        subField: 'message_videoclown',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie kind komt online',
        nested: true,
        field: 'settings',
        subField: 'message_child_online',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie clown komt online',
        nested: true,
        field: 'settings',
        subField: 'message_clown_online',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        name: 'Notificatie meest favoriete video van de week',
        nested: true,
        field: 'settings',
        subField: 'message_videofavorite',
        list: false,
        edit: true,
        boolean: false,
        type: 'CheckBox',
        default: 0,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      },
      {
        field: 'type',
        required: true
      },
      {
        required: true,
        nested: true,
        field: 'profile',
        subField: 'customReferer'
      }
    ];

    this.filters = [
      {
        name: 'Registratie Status',
        field: 'registration',
        type: 'DropDown',
        default: 'closed',
        options: [
          {
            value: 'Gesloten',
            key: 'closed'
          },
          {
            value: 'Geaccepteerd',
            key: 'accepted'
          },
          {
            value: 'Overleden',
            key: 'deceased'
          },
          {
            value: 'Afgekeurd',
            key: 'rejected'
          },
          {
            value: 'In afwachting',
            key: 'pending'
          }
        ]
      },
      {
        name: 'Type',
        field: 'type',
        type: 'DropDown',
        default: 'child',
        options: [
          {
            value: 'Kind',
            key: 'child'
          },
          {
            value: 'Clown',
            key: 'clown'
          },
          {
            value: 'Zorginstelling',
            key: 'institute'
          },
          {
            value: 'Medewerker',
            key: 'employee'
          },
          {
            value: 'Gast',
            key: 'guest'
          },
          {
            value: 'Test',
            key: 'test'
          }
        ]
      }
    ];

    return this;
  }
  /**
   * Dynamically create the correct bucket location for uploads
   * @returns {String} S3 bucket location based on current date
   */
  createImageUploadFolder = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${getEnvironmentVars().type}v2/images/profile/${yyyy}-${mm}-${dd}`;
  };

  modifyImagePath = image => {
    const bucketString = 'version2.appstorage.cliniclowns.nl';
    if (image.indexOf(bucketString) !== -1) {
      console.log('adding path');
      const splitImagePath = image.split(bucketString);
      return splitImagePath[1];
    } else {
      console.log('not adding path');
      return image;
    }
  };

  modifyCategory = category => {
    if (category && category.length > 0) {
      return category.toString();
    } else {
      return '';
    }
  };

  castToNumber = n => Number(n);

  getCellStyle = data => {
    switch (data.registration) {
      case 'accepted':
        return { background: 'green', color: 'white', padding: '5px' };
      case 'new':
        return { background: 'orange', color: 'white', padding: '5px' };
      case 'pending':
        return { background: 'purple', color: 'white', padding: '5px' };
      case 'rejected':
        return { background: 'red', color: 'white', padding: '5px' };
      case 'closed':
      case 'deceased':
        return { background: 'white', color: 'black', padding: '5px' };
    }
  };

  translate = data => {
    switch (data.registration) {
      case 'accepted':
        return 'Geaccepteerd';
      case 'new':
        return 'Nieuwe';
      case 'pending':
        return 'In afwachting';
      case 'rejected':
        return 'Afgekeurd';
      case 'closed':
        return 'Gesloten';
      case 'deceased':
        return 'Overleden';
    }
  };

  getRefererValue = data => {
    const values = data.getFields();
    return values.customReferer;
  };
};
