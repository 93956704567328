import { API, graphqlOperation } from 'aws-amplify';

/**
 * Call GraphQL mutation for the object passed to handle the deletion of its records
 * @param {Object} data - Data Object
 * @param {Array} ids - Array of ids
 * @returns {Object}
 */
async function DeleteIds(data, ids, deleteRepeating) {
  if (data.deleteOverride) return data.delete(ids);
  let mutation;
  let mutationResults;

  const idValue = `[${ids.toString()}]`;

  const inputStructure = data.delete.apiInput.replace(/idValue/, idValue);

  mutation = `mutation ${data.delete.apiCall}Mutation {
      ${data.delete.apiCall}(${inputStructure}${deleteRepeating ? ' deleteAll: true' : ''}) ${data.delete.apiReturnStructure}
    }`;

  const res = await API.graphql(graphqlOperation(mutation, {})).catch(error => {
    console.log('GRAPHQL ERROR', error);
    alert('oeps! ', error.message);
  });

  if (res.data && res.data[data.delete.apiCall]) {
    mutationResults = res.data[data.delete.apiCall];
  }

  return mutationResults;
}

export default DeleteIds;
