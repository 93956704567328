const SharedData = require('./SharedData');
const { Search } = require('@material-ui/icons');

module.exports = class ShowChatroom extends SharedData {
  constructor(options = {}) {
    super({
      id: 'id',
      nameField: 'chatrooms',
      nameSingle: 'show chat',
      nameMultiple: 'show chats',
      apiCallReturnSubtype: 'chatrooms',
      options: ['delete', 'deep'],
      search: {
        searchValues: {
          id: 'number',
          name: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          name
          updated_at
        }`
      },
      update: {
        apiCall: 'updateChatroom',
        apiInput: `id: idValue, input: { inputStructure }`,
        apiReturnStructure: `{
          result
          id
          extra
        }`
      },
      delete: {
        apiCall: 'CMSDeleteChatrooms'
      }
    });

    this.inputConstants = {
      is_show_chatroom: 1
    };

    if (options.constants) {
      this.inputConstants = {
        ...this.inputConstants,
        ...options.constants
      };
    }

    this.new = true;
    this.deleteApi = '';

    this.operand = 1;

    this.defaultOrder = 'updated_at desc';

    this.subIdList = [
      {
        id: 'id',
        name: Search,
        header: 'Berichten',
        field: 'chat_messages',
        deepSearchId: 'chatroom_id',
        link: '/app/berichten?subId=id&subIdField=chat_messages'
      }
    ];

    this.modifyOnInsertUpdate = {
      user_ids: this.modifyUserIds
    };

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Naam',
        field: 'name',
        type: 'TextField',
        required: false,
        list: true,
        edit: true
      },
      {
        name: '	Geupdate op',
        field: 'updated_at',
        type: 'Display',
        required: false,
        list: true,
        edit: false
      }
    ];

    return this;
  }

  modifyUserIds = userIds => {
    console.log('modifying userids', userIds, typeof userIds);
    return userIds;
  };

  parseUserName = data => data?.users?.toString();
};
