import { Search } from '../Search';

async function GetOptions(data) {
  if (Array.isArray(data)) return data;
  let returnData = [];
  const dataParsed = data;
  const returned = await Search(data, { unlimited: true });

  returned.rows.forEach(row => {
    returnData.push({ key: row[dataParsed.id], value: row[dataParsed.nameField] });
  });

  return returnData;
}

export default GetOptions;
