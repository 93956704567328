const SharedData = require('./SharedData');

module.exports = class UserExport extends SharedData {
  constructor() {
    super({
      id: 'id',
      nameField: 'file_name',
      nameSingle: 'export',
      nameMultiple: 'exports',
      apiCallReturnSubtype: 'user_exports',
      options: ['delete', 'export', 'deep'],
      search: {
        searchValues: {
          id: 'number',
          file_name: 'string',
          user_export_created_at: 'string',
          user_export_updated_at: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          file_name
          file_link
          user_export_created_at
          user_export_updated_at
        }`
      },
      export: {
        apiCall: 'initiateUserExport'
      },
      delete: {
        apiCall: 'deleteUserExport'
      }
    });

    this.defaultOrder = 'user_export_created_at desc';

    this.subIdList = [
      {
        id: 'file_link',
        linkInRow: true,
        name: 'Download Bestand',
        header: 'Download Bestand',
        field: 'file_link',
        deepSearchId: 'file_link'
      }
    ];

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Bestandsnaam',
        field: 'file_name',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Gemaakt bij',
        field: 'user_export_created_at',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Geüpdatet om',
        field: 'user_export_updated_at',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      }
    ];
  }
};
