import { format } from 'date-fns';
import convertMedia from './convertMedia';

export function RandomArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function MYSQLDate(date) {
  let dateformat = 'yyyy-MM-dd';
  return format(date, dateformat);
}

export function MYSQLDateTime(date) {
  if (date) {
    let dateformat = 'yyyy-MM-dd HH:mm:ss';
    return format(date, dateformat);
  } else {
    return '';
  }
}

export function GetUrlParams() {
  var params = {};
  if (typeof window != 'undefined') {
    var query = window.location.search.substring(1);
    var vars = query.split('&');

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
  }
  //console.log("get url params ",params);
  return params;
}

export function MakeUrlParams(values, clear) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  var params = {};
  if (!clear) {
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (!values[pair[0]]) {
        if (pair[0] != '') {
          params[pair[0]] = pair[1];
        }
      }
    }
  }
  //console.log("set url params ",params);
  let returnstring = '?';

  const vkeys = Object.keys(values);
  for (const vkey of vkeys) {
    if (values[vkey] != undefined && values[vkey] != null) {
      params[vkey] = values[vkey];
    }
  }

  const keys = Object.keys(params);
  let returns = [];
  for (const key of keys) {
    returns.push(key + '=' + params[key]);
  }

  return returnstring + returns.join('&');
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function LatLngDistance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

export function DateFromMysql(mysql_string) {
  if (mysql_string) {
    let t,
      result = null;

    if (typeof mysql_string === 'string') {
      mysql_string = mysql_string.replace('T', ' ');
      t = mysql_string.split('.')[0];

      t = t.split(/[- :]/);

      result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
    }

    return result;
  } else {
    return '';
  }
}

export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function mysqlEscape(stringToEscape) {
  stringToEscape = stringToEscape.toString();
  if (stringToEscape == '') {
    return stringToEscape;
  }

  return stringToEscape
    .replace(/\\/g, '\\\\')
    .replace(/\'/g, "\\'")
    .replace(/\"/g, '\\"')
    .replace(/\n/g, '\\\n')
    .replace(/\r/g, '\\\r')
    .replace(/\x00/g, '\\\x00')
    .replace(/\x1a/g, '\\\x1a');
}

export function isValidDate(date) {
  return date.getTime() === date.getTime();
}

export function idListToArray(idlist) {
  let returnArray = [];
  let tempids = idlist.split(',');
  for (let i = 0; i < tempids.length; i++) {
    let id = parseInt(tempids[i]);
    if (Number.isInteger(id)) {
      returnArray.push(id);
    }
  }
  return returnArray;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function isNumber(variable) {
  if (Number.isNaN(variable)) {
    return false;
  } else {
    return true;
  }
}

export function FixTimezone(date) {
  if (!date || !isValidDate(date)) {
    date = new Date();
  }
  let dateformat = 'yyyy-MM-dd HH:mm';
  let str = format(date, dateformat);
  let d = new Date(str);
  return d;
}

export function GetExpireDate() {
  let d = new Date();
  d = FixTimezone(d);
  d.setHours(d.getHours() + 72);
  return MYSQLDate(d);
}

export function GetNowWithSeconds(secs) {
  let seconds = parseInt(secs);
  //console.log("GETNOWWITHSECONDS: "+secs + " seconds "+seconds);
  let d = new Date();
  d = FixTimezone(d);
  d.setSeconds(d.getSeconds() + seconds);
  return MYSQLDate(d);
}

export let HrsDifference = 1;

export function Age(birthday) {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export async function startMediaConversion(urls) {
  const promises = [];
  const jobsAndUrls = {};
  for (let i = 0; i < urls.length; i++) {
    const recordingPath = urls[i];
    const fileId = recordingPath.split('/')[4].split('?')[0];
    promises.push(convertMedia(fileId));
  }
  await Promise.all(promises).then(values => {
    values.forEach(value => {
      jobsAndUrls[value.data.convertMedia.jobId] = value.data.convertMedia.signedUrl;
    });
  });

  return jobsAndUrls;
}
