import styled from 'styled-components/macro';
import ReactHlsPlayer from 'react-hls-player';
import { CircularProgress } from '@material-ui/core';
import { FormContext } from '../../../contexts/FormContext';
import React, { useEffect, useState, useContext } from 'react';

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

function audio(value) {
  return (
    <audio src={value} autoPlay={false} controls={true} width="100%" height="auto">
      <source src={value}></source>
    </audio>
  );
}

function image(value) {
  return <img src={value} width="100%" height="auto"></img>;
}

function video(value) {
  return (
    <video width="100%" height="auto" controls autoPlay>
      <source type="video/mp4" src={value}></source>
    </video>
  );
}

const components = {
  audio: audio,
  video: video,
  image: image
};

const MediaField = props => {
  const context = useContext(FormContext);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [component, setComponent] = useState('image');

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  function createMediaDisplayComponent() {
    if (value && value !== '') {
      if (value.indexOf('.mp4') !== -1) {
        setComponent('video');
      } else if (value.indexOf('.jpg') !== -1) {
        setComponent('image');
      } else {
        setComponent('audio');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);
    let newvalue = context.getValue(props.data.field);
    setValue(newvalue);
    let checked = checkValue(newvalue);
    context.setFieldStatus(props.data.field, checked.result, checked.error);
    setIsVisible(context.checkVisible(props.data.field));
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  useEffect(() => {
    checkAndUpdateValue(value);
    createMediaDisplayComponent();
  }, [value]);

  function externalValueUpdated(field, externalvalue) {
    if (field == props.data.field && value != externalvalue) {
      setValue(externalvalue);
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if (value.length == 0) {
        result = false;
        error = props.data.name + ' is required';
      } else {
        result = true;
      }
    }

    if (!completed) {
      result = false;
      error = 'File is still uploading, please wait';
    }
    return { result: result, error: error };
  }

  function checkAndUpdateValue(val) {
    let checked = checkValue(val);
    context.setFieldValue(props.data.field, val, checked.result, checked.error, props.data.dataTypes);
  }

  return (
    <>
      {isVisible && (
        <>
          <Container>{loading ? <CircularProgress /> : components[component](value)}</Container>
        </>
      )}
    </>
  );
};

export default MediaField;
