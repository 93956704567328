const SharedData = require('./SharedData');
const User = require('./User');
const { getEnvironmentVars } = require('../Config');

module.exports = class Agenda extends SharedData {
  constructor() {
    super({
      id: 'id',
      nameField: 'title',
      nameSingle: 'Agenda item',
      nameMultiple: 'Agenda items',
      apiCallReturnSubtype: 'agenda_v2',
      options: ['edit', 'delete', 'insert'],
      search: {
        searchValues: {
          id: 'number',
          title: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          days
          type
          title
          image
          has_chat
          clowns
          is_private
          is_started
          chatroom_id
          generated_by
          repeating
          webcamroom_id
          description
          endDateTime
          agora_channel
          endRepeatDate
          last_generate
          startDateTime
          recording_path
        }`
      },
      update: {
        apiCall: 'createUpdateAgendaItem',
        apiCallReturnSubtype: null,
        apiInput: `id:idValue, input: { inputStructure }`,
        apiReturnStructure: `{
          extra
          id
          result
          token
        }`
      },
      delete: {
        apiCall: 'deleteAgendaItem'
      }
    });

    this.repeating = true;

    this.subidname = 'name';

    this.defaultOrder = 'startDateTime desc';

    this.modifyOnInsertUpdate = {
      days: this.modifyRepeatingDays
    };

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Titel',
        field: 'title',
        type: 'TextField',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Startdatum / tijd',
        field: 'startDateTime',
        type: 'DateTime',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Einddatum / tijd',
        field: 'endDateTime',
        type: 'DateTime',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Clowns',
        field: 'clowns',
        type: 'SelectTransfer',
        options: new User({ constants: { type: 'clown' } }),
        required: true,
        list: false,
        edit: true,
        keyType: 'int'
      },
      {
        name: 'Omschrijving',
        edit: true,
        list: false,
        type: 'TextField',
        field: 'description'
      },
      {
        name: 'Foto',
        field: 'image',
        type: 'ImageUpload',
        required: false,
        list: false,
        edit: true,
        uploadFolder: this.createImageUploadFolder(),
        apiType: 'CMSImage'
      },
      {
        name: 'Herhaal',
        field: 'repeating',
        type: 'DropDown',
        options: [
          {
            value: 'Niet',
            key: 'not'
          },
          {
            value: 'Dagelijks',
            key: 'day'
          },
          {
            value: 'Bepaalde weekdagen',
            key: 'days'
          },
          {
            value: 'Wekelijks',
            key: 'week'
          },
          {
            value: 'Om de week',
            key: 'biweekly'
          },
          {
            value: 'Maandelijks',
            key: 'month'
          }
        ],
        required: true,
        list: true,
        edit: true,
        if: {
          true: [{ key: 'generated_by', value: null }]
        }
      },
      {
        name: 'Herhaal eind datum (tot)',
        field: 'endRepeatDate',
        type: 'DateTime',
        required: false,
        list: false,
        edit: true,
        if: {
          false: [
            { key: 'repeating', value: 'not' },
            { key: 'repeating', value: '' }
          ]
        }
      },
      {
        name: 'Herhaal op dagen',
        field: 'days',
        type: 'CheckBoxes',
        options: [
          {
            value: 'Maandag',
            key: 1
          },
          {
            value: 'Dinsdag',
            key: 2
          },
          {
            value: 'Woensdag',
            key: 3
          },
          {
            value: 'Donderdag',
            key: 4
          },
          {
            value: 'Vrijdag',
            key: 5
          },
          {
            value: 'Zaterdag',
            key: 6
          },
          {
            value: 'Zondag',
            key: 7
          }
        ],
        required: false,
        list: false,
        edit: true,
        if: {
          true: [{ key: 'repeating', value: 'days' }]
        }
      },
      {
        name: 'Type',
        field: 'type',
        type: 'DropDown',
        required: true,
        list: true,
        edit: true,
        options: [
          {
            value: 'Chat',
            key: 'chat'
          },
          {
            value: 'Radio',
            key: 'radio'
          },
          {
            value: 'Show',
            key: 'show'
          }
        ]
      }
    ];

    this.filters = [
      {
        name: 'Data filter',
        field: 'active',
        type: 'DropDown',
        default: false,
        options: [
          {
            value: 'Actief',
            key: true
          },
          {
            value: 'Alles',
            key: false
          }
        ]
      }
    ];

    return this;
  }

  /**
   * Dynamically create the correct bucket location for uploads
   * @returns {String} S3 bucket location based on current date
   */
  createImageUploadFolder = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${getEnvironmentVars().type}v2/images/library/${yyyy}-${mm}-${dd}`;
  };

  modifyRepeatingDays = (days, fields) => {
    console.log('modifyRepeatingDays', days, fields);

    if (fields?.repeating !== 'not') {
      if (!days) {
        days = [];
      }
      switch (fields.repeating) {
        case 'day':
          days = [1, 2, 3, 4, 5];
          break;
        case 'days':
          if (days === null) {
            days = [1, 2, 3, 4, 5, 6, 7];
          }
          break;
      }
    } else if (!days) {
      days = [];
    } else {
      days = [];
    }

    return days;
  };
};
