import styled from 'styled-components/macro';
import { spacing } from '@material-ui/system';
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Box, Button, TextField } from '@material-ui/core';

const MuiTextFieldStyled = styled(TextField)(spacing);

const NoClownsInput = props => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const query = `query getSettingsQuery {
      getSettings {
        theme
        versions
        maintenance
        minversion
        theme_icon
        theme_popup
        theme_splash
        extra_video_tag
        theme_icon_color
        theme_menu_phone
        theme_menu_tablet
        no_clowns_message
        extra_video_title
        theme_header_phone
        theme_header_tablet
      }
    }
    `;
    const res = await API.graphql(graphqlOperation(query, {})).catch(error => {
      console.log('GRAPHQL ERROR', error);
      alert(JSON.stringify(error));
    });

    setMessage(res?.data?.getSettings?.no_clowns_message);
  };

  const updateSettings = async () => {
    const mutation = `mutation updateSettingsMutation {
      updateSettings(input: {no_clowns_message: "${message}"}) {
        extra
        id
        result
        token
      }
    }    
    `;
    
    await API.graphql(graphqlOperation(mutation, {})).catch(error => {
      console.log('GRAPHQL ERROR', error);
      alert(JSON.stringify(error));
    });
  };

  const messageChanged = e => setMessage(e.target.value);

  return (
    <>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <MuiTextFieldStyled
          style={{ width: '100%' }}
          onChange={messageChanged}
          value={message}
          type={'text'}
          placeholder={'Geen clowns melding'}
        ></MuiTextFieldStyled>
        <Button onClick={updateSettings} color="primary">
          Pas aan
        </Button>
      </Box>
    </>
  );
};

export default NoClownsInput;
