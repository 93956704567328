const SharedData = require('./SharedData');
module.exports = class Alarm extends SharedData {
  constructor(options = {}) {
    super({
      id: 'id',
      nameField: 'id',
      nameSingle: 'alarm',
      nameMultiple: 'alarm',
      apiCallReturnSubtype: 'alarms',
      options: ['deep', 'archive'],
      search: {
        searchValues: {
          content: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          type
          user_id
          content
          is_seen
          created_at
          updated_at
          chatroom_id
          user_name
        }`
      },
      archive: {
        label: 'Gezien',
        field: 'is_seen',
        dataTypes: ['boolean'],
        apiCall: 'updateAlarm',
        apiInput: `id: idValue`,
        apiCallReturnSubtype: null,
        apiReturnStructure: `{
          extra
          id
          result
          token
        }`
      }
    });

    this.defaultOrder = 'created_at desc';

    this.subIdList = [
      {
        id: 'user_id',
        header: 'Gemeld door',
        name: this.parseUserName,
        field: 'user_id',
        deepSearchId: 'id',
        link: '/app/users?subId=user_id&subIdField=user_id'
      }
    ];

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Type',
        field: 'type',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Melding',
        field: 'content',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Gemeld op',
        field: 'created_at',
        required: true,
        list: true,
        edit: false
      },
      {
        field: 'user_name',
        required: true
      },
      {
        field: 'is_seen',
        required: true
      }
    ];

    this.filters = [
      {
        name: 'Gezien',
        field: 'is_seen',
        type: 'DropDown',
        default: 0,
        applied: true,
        options: [
          {
            value: 'Nee',
            key: 0
          },
          {
            value: 'Ja',
            key: 1
          }
        ]
      }
    ];

    return this;
  }

  parseUserName = data => data.user_name;
};
