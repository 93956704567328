import React, { useEffect, useState, useRef, useContext } from 'react';
import { Input, FormControl as MuiFormControl, Select, FormGroup, FormLabel, CircularProgress } from '@material-ui/core';

import { spacing } from '@material-ui/system';
import styled from 'styled-components/macro';
import { FormContext } from '../../../contexts/FormContext';
import GetOptions from '../../../utils/cms/GetOptions';

const FormControlSpacing = styled(MuiFormControl)(spacing);

const SelectMultiple = styled(Select)`
  width: 100%;
`;

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const SelectTable = styled.table`
  width: 100%;
  margin-top: 15px;
`;

const SelectTableTD = styled.td`
  width: 50%;
`;

const SelectTransfer = props => {
  const context = useContext(FormContext);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filter, setFilter] = useState('');
  const [unselectedOptions, setUnselectedOptions] = useState([]);
  const checkedOptions = useRef({});
  const [parsedOptions, setParsedOptions] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  /**
   * changes made for efficiency, instead of making the query 3 times, the query is now made once to retreive the items in the transfer
   * section, there were three queries because firstly the context useEffect was calling the makeOptions function which ran the query
   * as well as updating the value variable which was also calling the makeOptions function, and finally the updating of value was calling
   * the parseChange function which also sets the value variable making a third call to makeOptions
   *
   * this wouldnt be an issue because it is checked if parsedOptions exists before making the call and if it does just building instead but
   * because the query takes a bit of time to retrieve and parse the results, therefore it would not be set by the time the second and third
   * calls were being made
   *
   * the solution is to only call makeOptions when parsedOptions has been updated, which will allow for the time needed to set parsedOptions
   * before attempting the call several more times
   */
  useEffect(() => {
    context.events.addEventListener('change', parseChange);

    let newvalue = context.getValue(props.data.field);
    if (newvalue == '') {
      newvalue = null;
      setValue(newvalue);
      let checked = checkValue(newvalue);
      context.setFieldValue(props.data.field, null, checked.result, checked.error, props.data.dataTypes);
    } else {
      setValue(newvalue);
      let checked = checkValue(newvalue);
      context.setFieldStatus(props.data.field, checked.result, checked.error);
    }

    context.events.addEventListener('isVisible', parseVisible);
    setIsVisible(context.checkVisible(props.data.field));
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  useEffect(() => {
    makeOptions();
  }, [parsedOptions, filter]);

  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          let current = value[i];
          checkedOptions.current[current] = true;
        }
      } else {
        checkedOptions.current[value] = true;
      }
    }

    buildOptions();
  }, [value]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  async function makeOptions() {
    if (props.data.options) {
      if (!parsedOptions) {
        let parsedoptions = await GetOptions(props.data.options);
        setParsedOptions(parsedoptions);
      } else {
        buildOptions();
      }
    }
  }

  function buildOptions() {
    if (!parsedOptions) {
      setLoaded(false);
      return;
    }

    let HTMLSelectedOptions = [];
    let HTMLUnselectedOptions = [];
    parsedOptions.forEach(option => {
      if (checkedOptions.current[option.key]) {
        HTMLSelectedOptions.push(
          <option key={option.key + '-' + option.value + '1'} value={option.key}>
            {option.value}
          </option>
        );
      } else {
        if (filter == '' || option.value.toLowerCase().indexOf(filter) != -1) {
          HTMLUnselectedOptions.push(
            <option key={option.key + '-' + option.value + '1'} value={option.key}>
              {option.value}
            </option>
          );
        }
      }
    });
    setSelectedOptions(HTMLSelectedOptions);
    setUnselectedOptions(HTMLUnselectedOptions);
    setLoaded(true);
  }

  function externalValueUpdated(field, externalvalue) {
    if (field == props.data.field && value != externalvalue) {
      setValue(externalvalue);
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    if (props.data.required) {
      if (!value) {
        result = false;
        error = props.data.name + ' is required';
      } else {
        result = true;
      }
    }
    return { result: result, error: error };
  }

  function makeArray() {
    let list = [];
    for (let variable in checkedOptions.current) {
      if (checkedOptions.current[variable] == true) {
        let val = variable;
        if (props.data.keyType == 'int') {
          val = parseInt(val);
        }

        list.push(parseInt(variable));
      }
    }
    return list;
  }

  const valueUpdated = event => {
    let set = true;
    if (checkedOptions.current[event.target.value]) {
      set = false;
    }

    checkedOptions.current[event.target.value] = set;
    let list = makeArray();
    let checked = checkValue(list);
    context.setFieldValue(props.data.field, list, checked.result, checked.error, props.data.dataTypes);
  };

  const Filter = event => {
    setFilter(event.target.value.toLowerCase());
  };

  return (
    <Container>
      {loaded ? (
        isVisible && (
          <FormGroup>
            <FormLabel component="legend">
              {props.data.name} {props.data.singleSelectWarning ? '(Let op: je kunt maar één persoon kiezen)' : ''}
            </FormLabel>
            <Input placeholder="Filter" onChange={Filter} />

            <SelectTable>
              <tr>
                <SelectTableTD>
                  <FormLabel component="legend">Unselected ({unselectedOptions.length})</FormLabel>

                  <SelectMultiple
                    multiple
                    native
                    onChange={valueUpdated}
                    label="Native"
                    inputProps={{
                      id: 'select-multiple-native'
                    }}
                  >
                    {unselectedOptions}
                  </SelectMultiple>
                </SelectTableTD>
                <SelectTableTD>
                  <FormLabel component="legend">Selected ({selectedOptions.length})</FormLabel>
                  <SelectMultiple
                    multiple
                    native
                    onChange={valueUpdated}
                    label="Native"
                    inputProps={{
                      id: 'select-multiple-native'
                    }}
                  >
                    {selectedOptions}
                  </SelectMultiple>
                </SelectTableTD>
              </tr>
            </SelectTable>
          </FormGroup>
        )
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Container>
  );
};

export default SelectTransfer;
