import { FormContext } from '../../../contexts/FormContext';
import React, { useEffect, useState, useContext } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const CheckBox = props => {
  const fieldToUse = props.data.nested ? props.data.subField : props.data.field;
  const context = useContext(FormContext);
  const [value, setValue] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  function parseChange(e) {
    externalValueUpdated(e.detail.field, e.detail.value);
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    context.events.addEventListener('isVisible', parseVisible);
    let newvalue = context.getValue();
    if (newvalue == '') {
      newvalue = false;
    }
    setValue(newvalue);
    let checked = checkValue(newvalue);
    context.setFieldStatus(fieldToUse, checked.result, checked.error);
    setIsVisible(context.checkVisible(fieldToUse));
    return () => {
      context.events.removeEventListener('change', parseChange);
      context.events.removeEventListener('isVisible', parseVisible);
    };
  }, [context]);

  async function parseVisible(e) {
    let fieldToUpdate;

    if (props.data.nested) {
      fieldToUpdate = props.data.subField;
    } else {
      fieldToUpdate = props.data.field;
    }

    if (e.detail.field == fieldToUpdate) {
      setIsVisible(e.detail.isVisible);
    }
  }

  useEffect(() => {
    checkAndUpdateValue(value);
  }, [value]);

  function externalValueUpdated(field, externalvalue) {
    if (field == fieldToUse && value != externalvalue) {
      setValue(externalvalue);
    }
  }

  function checkValue(value) {
    let result = true;
    let error = '';
    return { result: result, error: error };
  }

  function checkAndUpdateValue(val) {
    let checked = checkValue(val);
    context.setFieldValue(fieldToUse, val, checked.result, checked.error, props.data.dataTypes);
  }

  const valueUpdated = event => {
    let newvalue = !value;
    if (!props.data.boolean) {
      newvalue = Number(newvalue);
    }
    setValue(newvalue);
    let checked = checkValue(newvalue);
    context.setFieldValue(fieldToUse, newvalue, checked.result, checked.error, props.data.dataTypes);
  };

  return (
    <>
      {isVisible && (
        <FormGroup fullWidth>
          <FormControlLabel control={<Checkbox checked={value} onChange={valueUpdated} />} label={props.data.name} />
        </FormGroup>
      )}
    </>
  );
};

export default CheckBox;
