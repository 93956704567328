const sendGraphQL = require('../../utils/sendGraphQL');

module.exports = class CMSUser {
  constructor() {
    this.id = 'Username';
    this.nameField = 'Username';
    this.nameSingle = 'CMS User';
    this.nameMultiple = 'CMS Users';
    this.options = ['edit', 'delete', 'insert'];
    this.insertOverride = true;
    this.deleteOverride = true;
    this.updateOverride = true;

    this.fields = [
      {
        name: 'Username',
        field: 'Username',
        type: 'TextInput',
        required: true,
        list: true,
        disabledSorting: true,
        edit: false
      },
      {
        name: 'UserStatus',
        field: 'UserStatus',
        type: 'Display',
        required: true,
        list: true,
        disabledSorting: true,
        edit: false
      },
      {
        name: 'Email',
        field: 'Email',
        type: 'TextField',
        required: true,
        list: true,
        create: true,
        edit: false
      },
      {
        name: 'Group',
        field: 'Group',
        type: 'DropDown',
        required: true,
        create: true,
        edit: true,
        options: [
          {
            value: 'admins',
            key: 'admins'
          },
          {
            value: 'clowns',
            key: 'clowns'
          },
          {
            value: 'specialists',
            key: 'specialists'
          }
        ]
      }
    ];

    this.filters = [
      {
        name: 'Status',
        field: 'accountStatus',
        type: 'DropDown',
        default: null,
        applied: false,
        options: [
          {
            value: 'UNKNOWN',
            key: 'UNKNOWN'
          },
          {
            value: 'CONFIRMED',
            key: 'CONFIRMED'
          },
          {
            value: 'UNCONFIRMED',
            key: 'UNCONFIRMED'
          },
          {
            value: 'RESET_REQUIRED',
            key: 'RESET_REQUIRED'
          },
          {
            value: 'EXTERNAL_PROVIDER',
            key: 'EXTERNAL_PROVIDER'
          },
          {
            value: 'FORCE_CHANGE_PASSWORD',
            key: 'FORCE_CHANGE_PASSWORD'
          }
        ]
      }
    ];

    this.rowsPerPageLimit = [10, 25, 50];

    this.search = async input => {
      let mutation = `query getCMSUsers {
        getCMSUsers {{params}} {
          data {
            Email
            Group
            Username
            UserStatus
            UserCreateDate
            UserLastModifiedDate
            Attributes {
              Name
              Value
            }
          }
          queryResultInfo {
            page
            limit
            count
            perpage
            paginationToken
          }
        }
      }`;

      const params = '('
        .concat(`${this.makeFilters(input)}`)
        .concat(`${this.makeOrder(input)}`)
        .concat(`${this.makePage(input)}`)
        .concat(`${this.makePaginationToken(input)}`)
        .concat(`${this.makePerPafe(input)}`)
        .concat(`${this.makeSearch(input)}`)
        .concat(')');

      if (params === '()') mutation = mutation.replace(/{{params}}/gi, '');
      else mutation = mutation.replace(/{{params}}/gi, params);

      const res = await sendGraphQL(mutation);

      return {
        rows: res?.data?.getCMSUsers?.data ?? [],
        queryResultInfo: res?.data?.getCMSUsers?.queryResultInfo
      };
    };

    this.searchForOne = async input => {
      let mutation = `query getCMSUser {
        getCMSUser(username: "${input.search}")  {
          Email
          Group
          Enabled
          Username
          UserStatus
          UserCreateDate
          UserLastModifiedDate
          Attributes {
            Name
            Value
          }
        }
      }`;

      const res = await sendGraphQL(mutation);

      return {
        rows: [res?.data?.getCMSUser] ?? []
      };
    };

    this.update = async (data, id) => {
      let mutation = `mutation addCMSUserToGroup {
        addCMSUserToGroup(group: ${data.Group}, username: "${id}") {
          Username
          UserStatus
          UserCreateDate
          UserLastModifiedDate
          Attributes {
            Name
            Value
          }
        }
      }`;

      const res = await sendGraphQL(mutation);

      return {
        rows: [res?.data?.addCMSUserToGroup] ?? []
      };
    };

    this.delete = async ids => {
      let mutation = `mutation deleteCMSUser {
        deleteCMSUser(username: "${ids[0]}") 
      }`;

      const res = await sendGraphQL(mutation);

      return res?.data?.deleteCMSUser;
    };

    this.insert = async data => {
      let mutation = `mutation createCMSUser {
        createCMSUser(email: "${data.Email}", group: ${data.Group}) {
          Username
          UserStatus
          UserCreateDate
          UserLastModifiedDate
          Attributes {
            Name
            Value
          }
        }
      }`;

      const res = await sendGraphQL(mutation);

      return {
        rows: [res?.data?.createCMSUser] ?? []
      };
    };

    return this;
  }

  getUserGroup(Attributes) {
    const group = Attributes.find(attr => attr.Name === 'group');
    return group ? group.Value : null;
  }

  parseAccountStatus(status) {
    switch (status) {
      case 'UNKNOWN':
        return 'Onbekend';
      case 'CONFIRMED':
        return 'Bevestigd';
      case 'UNCONFIRMED':
        return 'Onbevestigd';
      case 'RESET_REQUIRED':
        return 'Reset vereist';
      case 'EXTERNAL_PROVIDER':
        return 'Externe provider';
      case 'FORCE_CHANGE_PASSWORD':
        return 'Wachtwoord wijzigen';
      default:
        return 'Onbekend';
    }
  }

  makeFilters(input) {
    if (!input.filters) return '';
    const { accountStatus } = JSON.parse(input.filters);
    return accountStatus ? `filters: {accountStatus: ${JSON.parse(input.filters).accountStatus}} ` : '';
  }

  makeOrder(input) {
    return input.queryLimits.order ? `order: ${input.queryLimits.order} ` : '';
  }
  makePage(input) {
    return input.queryLimits.page ? `page: ${input.queryLimits.page} ` : '';
  }
  makePaginationToken(input) {
    return input.queryLimits.paginationToken ? `paginationToken: "${input.queryLimits.paginationToken}" ` : '';
  }
  makePerPafe(input) {
    return input.queryLimits.perpage ? `perpage: ${input.queryLimits.perpage} ` : '';
  }
  makeSearch(input) {
    return input.search ? `search: "${input.search.replace(/\W/gi, '').trim()}"` : '';
  }
};
