const { Search } = require('@material-ui/icons');
const SharedData = require('./SharedData');
module.exports = class ChatMessage extends SharedData {
  constructor() {
    super({
      id: 'id',
      nameField: 'chat_messages',
      nameSingle: 'bericht',
      nameMultiple: 'berichten',
      apiCallReturnSubtype: 'chat_messages',
      options: ['delete', 'deep', 'view'],
      search: {
        searchValues: {
          id: 'number',
          text: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          token
          user_id
          user_name
          chatroom_id
          send_at
          message_type
          text
          emote_type
          media_type
          media_url
          updated_at
          users
          chatroom_name
        }`
      },
      update: {
        apiCall: 'updateMessage',
        apiInput: `id: idValue, input: { inputStructure }`,
        apiReturnStructure: `{
          result
          id
          extra
          token
        }`
      },
      delete: {
        apiCall: 'deleteMessages'
      }
    });
    this.new = true;
    this.id = 'id';

    this.defaultOrder = 'send_at desc';

    this.deepTitle = true;

    this.subIdList = [
      {
        id: 'user_id',
        header: 'Wie',
        name: this.parseName,
        field: 'user_name',
        deepSearchId: 'id',
        link: '/app/users?subId=user_id&subIdField=user_name'
      },
      {
        field: 'id',
        name: Search,
        id: 'chatroom_id',
        header: 'Chatroom',
        displayInDeepSearch: false,
        deepSearchId: 'chatroom_id',
        link: '/app/berichten?subId=chatroom_id&subIdField=chat_messages'
      }
    ];

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        field: 'media_url',
        required: true,
        edit: true,
        type: 'MediaField'
      },
      {
        name: 'Gebruiker',
        field: 'user_name',
        type: 'TextField',
        required: false,
        list: true,
        edit: false
      },
      {
        name: 'Bericht',
        field: 'text',
        type: 'TextField',
        required: false,
        list: true,
        edit: true,
        customDisplay: this.parseTextDisplay
      },
      {
        name: 'Datum',
        field: 'send_at',
        type: 'TextField',
        required: false,
        list: true,
        edit: false
      },
      {
        field: 'user_id',
        required: true
      },
      {
        field: 'chatroom_id',
        required: true
      },
      {
        field: 'users',
        required: true
      },
      {
        field: 'chatroom_name',
        required: true
      },
      {
        field: 'media_type',
        required: true
      }
    ];

    return this;
  }

  parseTextDisplay = data => (data.media_type !== 'none' ? this.parseMediaType(data.media_type) : data.text);

  parseMediaType = type => {
    switch(type) {
      case 'imagefile':
        return 'Image';
      case 'videofile':
        return 'Video';
      case 'audiofile':
        return 'Audio';
    }
  }

  parseName = data => data.user_name;

  generateDeepTitle = data => {
    if (data[0]?.chatroom_name) {
      return `Berichten van ${data[0]?.chatroom_name}`;
    } else if (data[0]?.users) {
      return `Berichten tussen ${data[0]?.users?.toString()}`;
    } else {
      return 'Er zijn nog niet berichten';
    }
  };
};
