import axios from 'axios';

async function UploadFile(url, filedata, mime, callback) {
  let options = {
    headers: {
      'Content-Type': mime
    },
    onUploadProgress: progressEvent => {
      callback(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
    }
  };

  console.log('uploadfile ', url, filedata, mime);

  return new Promise(resolve => {
    axios
      .put(url, filedata, options)
      .catch(error => {
        console.log('UPLOAD error', error);
      })
      .then(response => {
        console.log('UPLOAD response', response);
        resolve();
      });
  });
}

export default UploadFile;
