import React, { useEffect, useState, useContext, useCallback } from 'react';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import { Link as NavLink } from 'gatsby';
import { Fields } from '../FormGenerator/FormFields';
import { FormContext } from '../../contexts/FormContext';
import debounce from 'lodash.debounce';

import { Card, ClickAwayListener } from '@material-ui/core';

const AbsoluteCard = styled(Card)`
  position: absolute !important;
  right: 25px !important;
  max-width: 25vw;
  z-index: 2;
  padding: 10px;
  box-shadow: 4px 5px 10px -1px rgba(0, 0, 0, 0.22) !important;
`;

const FilterList = props => {
  const [formHTML, setFormHTML] = useState([]);
  const [visible, setVisible] = useState(false);
  const context = useContext(FormContext);
  const debounceFilterUpdated = useCallback(debounce(props.onFilterUpdated, 100), []);

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    generateForm();
    return () => {
      context.events.removeEventListener('change', parseChange);
    };
  }, []);

  function parseChange(e) {
    // console.log('parse change inside filter list', e);
    let updated = false;
    let send = {};

    props.data.filters.forEach(field => {
      send[field.field.replace('internal--', '')] = context.getValue(field.field);
      if (e.detail.field == field.field) {
        updated = true;
      }
    });
    if (updated) {
      debounceFilterUpdated(send);
    }
  }

  function clickAway() {
    console.log('click away');
  }

  useEffect(() => {
    if (formHTML.length > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    // console.log('formhtml changed', formHTML);
  }, [formHTML]);

  function generateForm() {
    const fields = Fields();
    // console.log('fields inside filter list', fields);
    const generatedForm = [];
    let cnt = 0;
    props.data.filters.forEach(field => {
      // if (field.field.indexOf('internal--') == -1) {
      //   field.field = 'internal--' + field.field;
      // }

      // console.log('FIELD', field);

      let FieldType = fields[field.type];
      if (context.getValue(field.field, true) == '') {
        if (field.default !== null && field.default !== undefined) {
          context.setFieldValue(field.field, field.default);
        }
      }

      generatedForm.push(<FieldType key={field.name + cnt} data={field}></FieldType>);
      context.setVisible(field.field, true);
      cnt++;
    });
    setFormHTML(generatedForm);
  }

  return <AbsoluteCard variant="outlined">{formHTML}</AbsoluteCard>;
};

export default FilterList;
